/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useMediaQuery } from 'react-responsive'

const useStyles = makeStyles((theme) => ({
  sliderWrapperSmartphone: {
    borderColor: theme.palette.primary.main,
    borderWidth: 10,
    borderStyle: 'solid',
    backgroundColor: 'white',
    width: 250,
    height: 250,
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: 250,
    minHeight: 250,
    '& .carousel ul': {
      paddingInlineStart: '0px',
    },
  },
  sliderWrapperDesktop: {
    borderColor: theme.palette.primary.main,
    borderWidth: 5,
    borderStyle: 'solid',
    backgroundColor: 'white',
    width: 500,
    height: 500,
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: 500,
    minHeight: 500,
    '& .carousel ul': {
      paddingInlineStart: '0px',
    },
  },
  sliderImageSmartphone: {
    backgroundColor: 'white',
    color: 'white',
    maxHeight: 240,
  },
  sliderImageDesktop: {
    backgroundColor: 'white',
    color: 'white',
    maxHeight: 490,
  },
}));

const OlsProductImageSlider = (props) => {
  const classes = useStyles();
  const { album } = props;

  const isMobileScreen = useMediaQuery({ query: '(max-width: 480px)' });

  return (
    <div className={isMobileScreen ? classes.sliderWrapperSmartphone : classes.sliderWrapperDesktop} >
      <Carousel autoPlay infiniteLoop showArrows={false} showStatus={false} showThumbs={false}>
        {
          album.map((imgsrc, index) => <img key={index} className={isMobileScreen ? classes.sliderImageSmartphone : classes.sliderImageDesktop} alt="" src={imgsrc} />)
        }
      </Carousel>
    </div>
  );
};

export default OlsProductImageSlider;
