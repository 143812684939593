/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import "../../config";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import "@material-ui/core/Divider";
import {
  OlsTaxRow,
  OlsBulatRow,
  OlsShippingRow,
  OlsTotalRow,
} from "../../component/OlsRow";
import OlsCartRow from "../../component/OlsCartRow/OlsCartRow";
import TrxAlamatLengkap from "../../container/TrxAlamatLengkap/TrxAlamatLengkap";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import OlsCartEdit from "../../container/OlsCartEdit/OlsCartEdit";
import Toast from "../../component/Toast/Toast";
import Confirm from "../../component/Confirm/Confirm";
import "typeface-roboto";
import WhatsAppLink from "../../component/WhatsAppLink/WhatsAppLink";
import OlsFooter from "../../component/OlsFooter/OlsFooter";
import OlsNavbarShoppingCart from "../../component/OlsNavbar/OlsNavbarShoppingCart";
import Divider from "@material-ui/core/Divider";

let isMounted = false;
const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    paddingTop: 62,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  content: {
    padding: 4,
    display: "block",
    minHeight: 1000,
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
  },
  contentCartWrapper: {
    marginTop: 0,
    padding: 0,
    display: "block",
    width: "100%",
  },
  headerCartWrapper: {
    padding: "4px 4px 2px 4px",
  },
  headerCartContent: {
    borderRadius: "15px 15px 0px 0px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    padding: 16,
    boxShadow: "1px 3px 1px #9E9E9E",
  },
  buttonStyle: {
    margin: "10px 0px",
    borderRadius: 0,
    padding: 15,
    fontWeight: "bold",
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otl_info: ls.get(`otl_info`) || {},
      trx_info: {},
      cart_list: [],
      loading: false,
      cartEdit: false,
      cartEditData: {},
      toastOpen: false,
      toastMessage: "",
      toastSeverity: "info",
      confirmBayarDiToko: false,
      confirmBayarViaTransfer: false,
      confirmCancel: false,
    };
  }

  componentDidMount() {
    isMounted = true;

    let otl_info = ls.get(`otl_info`) || {};
    this.setState({
      otl_info: otl_info,
    });
    if (_.isString(otl_info.otl_name)) {
      document.title = otl_info.otl_name;
    }

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });

    this.otlTrxInfo();
    window.scrollTo(0, 0);
  }

  otlTrxInfo = () => {
    const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
    const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
    this.setState({ loading: true });
    const formdatao = new FormData();
    axios
      .post(base_url + "/olshop_api/trx/trx_info/", formdatao)
      .then((response) => {
        if (isMounted) {
          this.setState({ loading: false });
          if (_.isObject(response.data.trx_info)) {
            const trxInfoResp = response.data.trx_info;
            if (_.isString(trxInfoResp.trx_uxid)) {
              this.setState({ trx_info: trxInfoResp });
              ls.set(lsShoppingCartTrxInfo, trxInfoResp);
            }
          }
          if (_.isArray(response.data.cart_list)) {
            const cartListResp = response.data.cart_list;
            this.setState({ cart_list: cartListResp });
            ls.set(lsShoppingCartCartList, cartListResp);
          }
        }
      });
  };

  componentWillUnmount() {
    isMounted = false;
  }

  handleGoBack = () => {
    const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
    const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
    if (_.isString(this.state.trx_info.trx_status)) {
      if (this.state.trx_info.trx_status !== "TEMP") {
        ls.set(lsShoppingCartTrxInfo, {});
        ls.set(lsShoppingCartCartList, []);
      }
    }
    if (this.props.history.length > 0) {
      this.props.history.goBack();
    }
  };

  handleAlamatLengkapChange = (dt) => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("trx_uxid", this.state.trx_info.trx_uxid);
      formdata.append("trx_client_realname", dt.trx_client_realname);
      formdata.append("trx_client_addr1", dt.trx_client_addr1);
      formdata.append("trx_client_addr2", dt.trx_client_addr2);
      formdata.append("trx_client_addr3", dt.trx_client_addr3);
      formdata.append("trx_client_addr4", dt.trx_client_addr4);
      formdata.append("trx_client_addr5", dt.trx_client_addr5);
      formdata.append("trx_client_phone", dt.trx_client_phone);
      axios
        .post(base_url + "/olshop_api/trx/trx_addr_update/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isObject(response.data.trx_info)) {
              const trxInfoResp = response.data.trx_info;
              if (_.isString(trxInfoResp.trx_uxid)) {
                this.setState({ trx_info: trxInfoResp });
                ls.set(lsShoppingCartTrxInfo, trxInfoResp);
              }
            }
            if (_.isArray(response.data.cart_list)) {
              const cartListResp = response.data.cart_list;
              this.setState({ cart_list: cartListResp });
              ls.set(lsShoppingCartCartList, cartListResp);
            }
            //= toast start
            if (_.isString(response.data.status)) {
              if (response.data.status === "OK") {
                if (_.isString(response.data.message)) {
                  const { message } = response.data;
                  this.toastShow("success", message);
                }
              } else if (_.isString(response.data.message)) {
                const { message } = response.data;
                this.toastShow("error", message);
              }
            }
            //= toast end
          }
        });
    }
  };

  handleCartEditOpen = (data) => {
    if (isMounted) {
      if (this.state.trx_info.trx_status === "TEMP") {
        this.setState({
          cartEdit: true,
          cartEditData: data,
        });
      }
    }
  };

  handleCartEditClose = () => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
    }
  };

  handleCartEditDelete = (fields) => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
      const usrInfo = ls.get("usr_info");
      if (_.isString(usrInfo.usr_realname)) {
        if (_.isString(this.state.trx_info.trx_uxid)) {
          // untuk memastikan object trx terload dengan benar
          if (this.state.trx_info.trx_status === "TEMP") {
            const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
            const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
            this.setState({ loading: true });
            const formdata = new FormData();
            formdata.append("cart_uxid", fields.cart_uxid);
            formdata.append("cart_qty", fields.cart_qty);
            formdata.append("cart_note", fields.cart_note);
            axios
              .post(base_url + "/olshop_api/trx/cart_delete/", formdata)
              .then((response) => {
                if (isMounted) {
                  this.setState({ loading: false });
                  if (_.isObject(response.data.trx_info)) {
                    const trxInfoResp = response.data.trx_info;
                    if (_.isString(trxInfoResp.trx_uxid)) {
                      this.setState({ trx_info: trxInfoResp });
                      ls.set(lsShoppingCartTrxInfo, trxInfoResp);
                    }
                  }
                  if (_.isArray(response.data.cart_list)) {
                    const cartListResp = response.data.cart_list;
                    this.setState({ cart_list: cartListResp });
                    ls.set(lsShoppingCartCartList, cartListResp);
                  }
                  //= toast start
                  if (_.isString(response.data.status)) {
                    if (response.data.status === "OK") {
                      if (_.isString(response.data.message)) {
                        const { message } = response.data;
                        this.toastShow("success", message);
                      }
                    } else if (_.isString(response.data.message)) {
                      const { message } = response.data;
                      this.toastShow("error", message);
                    }
                  }
                  //= toast end
                }
              });
          } else {
            this.toastShow("error", "Tidak dapat hapus belanja");
          }
        }
      } else {
        this.props.history.push("/login");
      }
    }
  };

  handleCartEditSave = (fields) => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
      const usrInfo = ls.get("usr_info");
      if (_.isString(usrInfo.usr_realname)) {
        if (_.isString(this.state.trx_info.trx_uxid)) {
          // untuk memastikan object trx terload dengan benar
          if (this.state.trx_info.trx_status === "TEMP") {
            const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
            const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
            this.setState({ loading: true });
            const formdata = new FormData();
            formdata.append("cart_uxid", fields.cart_uxid);
            formdata.append("cart_qty", fields.cart_qty);
            formdata.append("cart_note", fields.cart_note);
            axios
              .post(base_url + "/olshop_api/trx/cart_update/", formdata)
              .then((response) => {
                if (isMounted) {
                  this.setState({ loading: false });
                  if (_.isObject(response.data.trx_info)) {
                    const trxInfoResp = response.data.trx_info;
                    if (_.isString(trxInfoResp.trx_uxid)) {
                      this.setState({ trx_info: trxInfoResp });
                      ls.set(lsShoppingCartTrxInfo, trxInfoResp);
                    }
                  }
                  if (_.isArray(response.data.cart_list)) {
                    const cartListResp = response.data.cart_list;
                    this.setState({ cart_list: cartListResp });
                    ls.set(lsShoppingCartCartList, cartListResp);
                  }
                  //= toast start
                  if (_.isString(response.data.status)) {
                    if (response.data.status === "OK") {
                      if (_.isString(response.data.message)) {
                        const { message } = response.data;
                        this.toastShow("success", message);
                      }
                    } else if (_.isString(response.data.message)) {
                      const { message } = response.data;
                      this.toastShow("error", message);
                    }
                  }
                  //= toast end
                }
              });
          } else {
            this.toastShow("error", "Tidak dapat update belanja");
          }
        }
      } else {
        this.props.history.push("/login");
      }
    }
  };

  toastShow = (severity, message) => {
    this.setState({
      toastOpen: true,
      toastMessage: message,
      toastSeverity: severity,
    });
  };

  toastOnClose = () => {
    this.setState({
      toastOpen: false,
    });
  };

  handleTrxBayarDiToko = () => {
    this.setState({
      confirmBayarDiToko: true,
    });
  };

  confirmBayarDiTokoClose = () => {
    this.setState({
      confirmBayarDiToko: false,
    });
  };

  confirmBayarDiTokoYes = () => {
    if (isMounted) {
      this.setState({
        confirmBayarDiToko: false,
      });
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("trx_uxid", this.state.trx_info.trx_uxid);
      axios
        .post(base_url + "/olshop_api/trx/trx_bayar_di_toko/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isObject(response.data.trx_info)) {
              const trxInfoResp = response.data.trx_info;
              if (_.isString(trxInfoResp.trx_uxid)) {
                this.setState({ trx_info: trxInfoResp });
                ls.set(lsShoppingCartTrxInfo, trxInfoResp);
              }
            }
            if (_.isArray(response.data.cart_list)) {
              const cartListResp = response.data.cart_list;
              this.setState({ cart_list: cartListResp });
              ls.set(lsShoppingCartCartList, cartListResp);
            }
            //= toast start
            if (_.isString(response.data.status)) {
              if (response.data.status === "OK") {
                this.props.history.push("/trx");
              } else if (_.isString(response.data.message)) {
                const { message } = response.data;
                this.toastShow("error", message);
              }
            }
            //= toast end
          }
        });
    }
  };

  handleTrxBayarViaTransfer = () => {
    this.setState({
      confirmBayarViaTransfer: true,
    });
  };

  confirmBayarViaTransferClose = () => {
    this.setState({
      confirmBayarViaTransfer: false,
    });
  };

  confirmBayarViaTransferYes = () => {
    if (isMounted) {
      this.setState({
        confirmBayarViaTransfer: false,
      });
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("trx_uxid", this.state.trx_info.trx_uxid);
      axios
        .post(base_url + "/olshop_api/trx/trx_bayar_via_transfer/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isObject(response.data.trx_info)) {
              const trxInfoResp = response.data.trx_info;
              if (_.isString(trxInfoResp.trx_uxid)) {
                this.setState({ trx_info: trxInfoResp });
                ls.set(lsShoppingCartTrxInfo, trxInfoResp);
              }
            }
            if (_.isArray(response.data.cart_list)) {
              const cartListResp = response.data.cart_list;
              this.setState({ cart_list: cartListResp });
              ls.set(lsShoppingCartCartList, cartListResp);
            }
            //= toast start
            if (_.isString(response.data.status)) {
              if (response.data.status === "OK") {
                this.props.history.push("/trx");
              } else if (_.isString(response.data.message)) {
                const { message } = response.data;
                this.toastShow("error", message);
              }
            }
            //= toast end
          }
        });
    }
  };

  confirmCancelOpen = () => {
    this.setState({
      confirmCancel: true,
    });
  };

  confirmCancelClose = () => {
    this.setState({
      confirmCancel: false,
    });
  };

  confirmCancelYes = () => {
    if (isMounted) {
      this.setState({
        confirmCancel: false,
      });
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("trx_uxid", this.state.trx_info.trx_uxid);
      axios
        .post(base_url + "/olshop_api/trx/trx_cancel/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isObject(response.data.trx_info)) {
              const trxInfoResp = response.data.trx_info;
              if (_.isString(trxInfoResp.trx_uxid)) {
                this.setState({ trx_info: trxInfoResp });
                ls.set(lsShoppingCartTrxInfo, trxInfoResp);
              }
            }
            if (_.isArray(response.data.cart_list)) {
              const cartListResp = response.data.cart_list;
              this.setState({ cart_list: cartListResp });
              ls.set(lsShoppingCartCartList, cartListResp);
            }
            //= toast start
            if (_.isString(response.data.status)) {
              if (response.data.status === "OK") {
                if (_.isString(response.data.message)) {
                  const { message } = response.data;
                  this.toastShow("success", message);
                }
              } else if (_.isString(response.data.message)) {
                const { message } = response.data;
                this.toastShow("error", message);
              }
            }
            //= toast end
            window.scrollTo(0, 0);
          }
        });
    }
  };

  handleShoppingCartOpen = () => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      ls.set(lsShoppingCartTrxInfo, this.state.trx_info);
      ls.set(lsShoppingCartCartList, this.state.cart_list);
      this.props.history.push("/shoppingcart");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {_.size(this.state.cart_list) <= 0 && (
          <div style={{ paddingBottom: 56 }}>
            <div className={classes.page}>
              <div style={{ height: 18 }}></div>
              <div style={{ paddingBottom: 56 }}>
                <div className={classes.content}>
                  <div style={this.state.loading ? { display: "none" } : {}}>
                    <div style={{ marginTop: "100px", textAlign: "center" }}>
                      <ShoppingBasketIcon
                        style={{
                          textAlign: "center",
                          color: "#808080",
                          fontSize: 140,
                        }}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        style={{ color: "#808080" }}
                        variant="h6"
                        noWrap
                      >
                        belum ada daftar belanja
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <OlsFooter outlet={this.state.otl_info} />
          </div>
        )}
        {_.size(this.state.cart_list) > 0 && (
          <div style={{ paddingBottom: 56 }}>
            <div className={classes.page}>
              <div style={{ height: 18 }}></div>

              <div style={{ paddingBottom: 56 }}>
                <div className={classes.content}>
                  <TrxAlamatLengkap
                    editable={this.state.trx_info.trx_status === "TEMP"}
                    data={this.state.trx_info}
                    onChange={this.handleAlamatLengkapChange}
                  />
                  <div className={classes.headerCartWrapper}>
                    <div className={classes.headerCartContent}>
                      <Typography variant="h6" noWrap>
                        Daftar Belanja
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.contentCartWrapper}>
                    {this.state.cart_list.map((cart, index) => (
                      <OlsCartRow
                        key={index}
                        data={cart}
                        onOpen={(data) => this.handleCartEditOpen(data)}
                      />
                    ))}
                  </div>

                  {this.state.trx_info.trx_tax_percent > 0 && (
                    <OlsTaxRow
                      percent={this.state.trx_info.trx_tax_percent}
                      value={this.state.trx_info.trx_tax_value}
                    />
                  )}
                  {this.state.trx_info.trx_shipping_cost > 0 && (
                    <OlsShippingRow
                      label="Biaya Pengiriman"
                      value={this.state.trx_info.trx_shipping_cost}
                    />
                  )}
                  {this.state.trx_info.trx_amal > 0 && (
                    <OlsBulatRow
                      label="Pembulatan / Titip amal"
                      value={this.state.trx_info.trx_amal}
                    />
                  )}

                  <OlsTotalRow
                    label="Jumlah Total"
                    value={this.state.trx_info.trx_total}
                  />

                  {this.state.trx_info.trx_status === "TEMP" && (
                    <div>
                      <Button
                        className={classes.buttonStyle}
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={this.handleTrxBayarDiToko}
                      >
                        BAYAR DI TOKO
                      </Button>
                      {this.state.trx_info.trx_transfer_enable === "1" && (
                        <Button
                          className={classes.buttonStyle}
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={this.handleTrxBayarViaTransfer}
                        >
                          BAYAR VIA TRANSFER
                        </Button>
                      )}
                      <Button
                        className={classes.buttonStyle}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={this.confirmCancelOpen}
                      >
                        BATALKAN TRANSAKSI
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <OlsFooter outlet={this.state.otl_info} />

            <OlsCartEdit
              data={this.state.cartEditData}
              onClose={this.handleCartEditClose}
              onSave={this.handleCartEditSave}
              onDelete={this.handleCartEditDelete}
              open={this.state.cartEdit}
            />
            <Confirm
              open={this.state.confirmBayarDiToko}
              text="Yakin ingin memproses transaksi ini yang selanjutnya pembayaran dilakukan di toko?"
              onClose={this.confirmBayarDiTokoClose}
              onYes={this.confirmBayarDiTokoYes}
            />
            <Confirm
              open={this.state.confirmBayarViaTransfer}
              text="Yakin ingin memproses transaksi ini yang selanjutnya pembayaran dilakukan melalui transfer? Kami akan mengecek terlebih dahulu transaksi anda"
              onClose={this.confirmBayarViaTransferClose}
              onYes={this.confirmBayarViaTransferYes}
            />
            <Confirm
              open={this.state.confirmCancel}
              text="Yakin ingin membatalkan transaksi?"
              onClose={this.confirmCancelClose}
              onYes={this.confirmCancelYes}
            />
          </div>
        )}

        <OlsNavbarShoppingCart
          value=" "
          title="Keranjang Belanja"
          text={
            this.state.otl_info.otl_name
              ? `Hallo ${this.state.otl_info.otl_name}`
              : "Hallo"
          }
          outlet={this.state.otl_info}
          cartCount={_.size(this.state.cart_list)}
          onCartClick={this.handleShoppingCartOpen}
          onBackClick={this.handleGoBack}
        />

        <div className={classes.displayMobile}>
          <WhatsAppLink
            phone={this.state.otl_info.otl_whatsapp}
            label="Butuh Bantuan ?"
            text="Bantu Cara Transaksi"
          />
          <OlsBottomNav />
        </div>

        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>

        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          severity={this.state.toastSeverity}
          onClose={this.toastOnClose}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(ShoppingCart);
