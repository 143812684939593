/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import "../../config";
import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Button from "@material-ui/core/Button";
import ls from "local-storage";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import { Container } from "@material-ui/core";
import "typeface-roboto";
import NumberFormat from "react-number-format";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import OlsProductImageSlider from "../../container/OlsProductImageSlider/OlsProductImageSlider";
import OlsCartEdit from "../../container/OlsCartEdit/OlsCartEdit";
import Toast from "../../component/Toast/Toast";
import OlsFooter from "../../component/OlsFooter/OlsFooter";
import OlsNavbarProduct from "../../component/OlsNavbar/OlsNavbarProduct";
import WhatsAppLink from "../../component/WhatsAppLink/WhatsAppLink";

let isMounted = false;

const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    paddingTop: 62,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  content: {
    padding: 4,
    display: "block",
    minHeight: 1000,
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  discount: {
    color: "red",
    fontWeight: "bold",
  },
  buttonStyle: {
    margin: "10px 0px",
    borderRadius: 0,
    padding: 15,
    fontWeight: "bold",
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usr_info: ls.get("usr_info") || false,
      otl_info: ls.get(`otl_info`) || {},
      trx_info: ls.get(`otl_ShoppingCart_TrxInfo`) || {},
      cart_list: [],
      product_info: {},
      image_album: [],
      loading: false,
      cartEdit: false,
      cartEditData: {},
      toastOpen: false,
      toastMessage: "",
      toastSeverity: "info",
    };
  }

  componentDidMount() {
    isMounted = true;

    let otl_info = ls.get(`otl_info`) || {};
    this.setState({
      otl_info: otl_info,
    });

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });
    if (usr_info !== false) {
      if (_.isString(usr_info.usr_uxid)) {
        const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
        const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
        const trxInfo = ls.get(lsShoppingCartTrxInfo);
        if (_.isObject(trxInfo)) {
          if (_.isString(trxInfo.trx_client_uxid)) {
            if (trxInfo.trx_client_uxid === usr_info.usr_uxid) {
              this.setState({ trx_info: trxInfo });
              const cartList = ls.get(lsShoppingCartCartList);
              if (_.isArray(cartList)) {
                this.setState({ cart_list: cartList });
              }
            }
          }
        }
      }
    }

    if (this.props.match.params) {
      if (_.isString(this.props.match.params.uxid)) {
        const { uxid } = this.props.match.params;
        const productViewTemp = `otl_product_${uxid}`;
        const productInfo = ls.get(productViewTemp);
        if (_.isObject(productInfo)) {
          if (_.isString(productInfo.product_image_url)) {
            const productDefaultImage = productInfo.product_image_url;
            this.setState({ image_album: [productDefaultImage] });
          }
          this.setState({
            product_info: productInfo || {},
          });
          if (_.isString(productInfo.product_name)) {
            document.title = productInfo.product_name;
          }
        }
        this.setState({ loading: true });
        const formdatai = new FormData();
        formdatai.append("product_uxid", uxid);
        axios
          .post(base_url + "/olshop_api/product/product_uxid_info/", formdatai)
          .then((response) => {
            if (isMounted) {
              this.setState({ loading: false });
              if (_.isObject(response.data.product_info)) {
                const productInfoResp = response.data.product_info;
                if (_.isString(productInfoResp.product_uxid)) {
                  this.setState({
                    product_info: productInfoResp,
                  });
                  const productViewTempResp = `otl_product_${productInfoResp.product_uxid}`;
                  ls.set(productViewTempResp, productInfoResp);
                  if (_.isArray(response.data.photo_list)) {
                    this.setState({ image_album: response.data.photo_list });
                  }
                }
                if (_.isString(productInfoResp.product_name)) {
                  document.title = productInfoResp.product_name;
                }
              }
              //= toast start
              if (_.isString(response.data.status)) {
                if (response.data.status === "OK") {
                  if (_.isString(response.data.message)) {
                    const { message } = response.data;
                    this.toastShow("success", message);
                  }
                } else if (_.isString(response.data.message)) {
                  const { message } = response.data;
                  this.toastShow("error", message);
                }
              }
              //= toast end
            }
          });
      }
    }

    this.pageInfo();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    isMounted = false;
  }

  pageInfo = () => {
    this.setState({ loading: true });
    const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
    const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios
      .post(base_url + "/olshop_api/page/info/", formdatau)
      .then((response) => {
        if (isMounted) {
          this.setState({ loading: false });
          if (_.isObject(response.data.usr_info)) {
            this.setState({ usr_info: response.data.usr_info });
            ls.set("usr_info", response.data.usr_info);
          }
          if (_.isObject(response.data.otl_info)) {
            let otl_info = response.data.otl_info;
            this.setState({
              otl_info: otl_info,
            });
            ls.set(`otl_info`, otl_info);
            if (_.isString(otl_info.otl_name)) {
              document.title = otl_info.otl_name;
            }
          }
          if (_.isArray(response.data.cart_list)) {
            const cartListResp = response.data.cart_list;
            this.setState({ cart_list: cartListResp });
            ls.set(lsShoppingCartCartList, cartListResp);
          }
          if (_.isObject(response.data.trx_info)) {
            const trxInfoResp = response.data.trx_info;
            if (_.isString(trxInfoResp.trx_uxid)) {
              if (trxInfoResp.trx_status !== "TEMP") {
                ls.set(lsShoppingCartTrxInfo, {});
                ls.set(lsShoppingCartCartList, []);
                this.setState({ trx_info: {} });
                this.setState({ cart_list: [] });
              } else {
                this.setState({ trx_info: trxInfoResp });
                ls.set(lsShoppingCartTrxInfo, trxInfoResp);
              }
            }
          }
        }
      });
  };

  handleGoBack = () => {
    if (this.props.history.length > 0) {
      this.props.history.goBack();
    }
  };

  toastShow = (severity, message) => {
    this.setState({
      toastOpen: true,
      toastMessage: message,
      toastSeverity: severity,
    });
  };

  toastOnClose = () => {
    this.setState({
      toastOpen: false,
    });
  };

  handleCartEditClose = () => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
    }
  };

  handleCartEditDelete = (fields) => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
      const usrInfo = ls.get("usr_info");
      if (_.isString(usrInfo.usr_realname)) {
        if (_.isString(this.state.trx_info.trx_uxid)) {
          // untuk memastikan object trx terload dengan benar
          // untuk memastikan object product terload dengan benar
          if (this.state.trx_info.trx_status === "TEMP") {
            const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
            const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
            this.setState({ loading: true });
            const formdata = new FormData();
            formdata.append("cart_uxid", fields.cart_uxid);
            formdata.append("cart_qty", fields.cart_qty);
            formdata.append("cart_note", fields.cart_note);
            axios
              .post(base_url + "/olshop_api/trx/cart_delete/", formdata)
              .then((response) => {
                if (isMounted) {
                  this.setState({ loading: false });
                  if (_.isObject(response.data.trx_info)) {
                    const trxInfoResp = response.data.trx_info;
                    if (_.isString(trxInfoResp.trx_uxid)) {
                      this.setState({ trx_info: trxInfoResp });
                      ls.set(lsShoppingCartTrxInfo, trxInfoResp);
                    }
                  }
                  if (_.isArray(response.data.cart_list)) {
                    const cartListResp = response.data.cart_list;
                    this.setState({ cart_list: cartListResp });
                    ls.set(lsShoppingCartCartList, cartListResp);
                  }
                  //= toast start
                  if (_.isString(response.data.status)) {
                    if (response.data.status === "OK") {
                      if (_.isString(response.data.message)) {
                        const { message } = response.data;
                        this.toastShow("success", message);
                      }
                    } else if (_.isString(response.data.message)) {
                      const { message } = response.data;
                      this.toastShow("error", message);
                    }
                  }
                  //= toast end
                }
              });
          } else {
            this.toastShow("error", "Tidak dapat hapus belanja");
          }
        }
      } else {
        this.props.history.push("/login");
      }
    }
  };

  handleCartEditSave = (fields) => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
      const usrInfo = ls.get("usr_info");
      if (_.isString(usrInfo.usr_realname)) {
        if (_.isString(this.state.trx_info.trx_uxid)) {
          // untuk memastikan object trx terload dengan benar
          // untuk memastikan object product terload dengan benar
          if (this.state.trx_info.trx_status === "TEMP") {
            const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
            const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
            this.setState({ loading: true });
            const formdata = new FormData();
            formdata.append("cart_uxid", fields.cart_uxid);
            formdata.append("cart_qty", fields.cart_qty);
            formdata.append("cart_note", fields.cart_note);
            axios
              .post(base_url + "/olshop_api/trx/cart_update/", formdata)
              .then((response) => {
                if (isMounted) {
                  this.setState({ loading: false });
                  if (_.isObject(response.data.trx_info)) {
                    const trxInfoResp = response.data.trx_info;
                    if (_.isString(trxInfoResp.trx_uxid)) {
                      this.setState({ trx_info: trxInfoResp });
                      ls.set(lsShoppingCartTrxInfo, trxInfoResp);
                    }
                  }
                  if (_.isArray(response.data.cart_list)) {
                    const cartListResp = response.data.cart_list;
                    this.setState({ cart_list: cartListResp });
                    ls.set(lsShoppingCartCartList, cartListResp);
                  }
                  //= toast start
                  if (_.isString(response.data.status)) {
                    if (response.data.status === "OK") {
                      if (_.isString(response.data.message)) {
                        const { message } = response.data;
                        this.toastShow("success", message);
                      }
                    } else if (_.isString(response.data.message)) {
                      const { message } = response.data;
                      this.toastShow("error", message);
                    }
                  }
                  //= toast end
                }
              });
          } else {
            this.toastShow("error", "Tidak dapat update belanja");
          }
        }
      } else {
        this.props.history.push("/login");
      }
    }
  };

  handleClickBuy = () => {
    if (isMounted) {
      this.setState({
        cartView: false,
      });
      const usrInfo = ls.get("usr_info");
      let productBuy = this.state.product_info;
      if (_.isString(usrInfo.usr_realname)) {
        if (_.isString(this.state.trx_info.trx_uxid)) {
          // untuk memastikan object trx terload dengan benar
          if (_.isString(productBuy.product_uxid)) {
            // untuk memastikan object product terload dengan benar
            if (this.state.trx_info.trx_status === "TEMP") {
              const cart = _.find(this.state.cart_list, {
                cart_product_uxid: productBuy.product_uxid,
              });
              if (_.isObject(cart)) {
                this.setState({
                  cartEdit: true,
                  cartEditData: cart,
                });
              } else {
                const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
                const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
                this.setState({ loading: true });
                const formdata = new FormData();
                formdata.append("cart_trx_uxid", this.state.trx_info.trx_uxid);
                formdata.append("cart_product_uxid", productBuy.product_uxid);
                formdata.append("cart_qty", 1);
                formdata.append("cart_note", "");
                axios
                  .post(base_url + "/olshop_api/trx/cart_add/", formdata)
                  .then((response) => {
                    if (isMounted) {
                      this.setState({ loading: false });
                      if (_.isObject(response.data.trx_info)) {
                        const trxInfoResp = response.data.trx_info;
                        if (_.isString(trxInfoResp.trx_uxid)) {
                          this.setState({ trx_info: trxInfoResp });
                          ls.set(lsShoppingCartTrxInfo, trxInfoResp);
                        }
                      }
                      if (_.isArray(response.data.cart_list)) {
                        const cartListResp = response.data.cart_list;
                        this.setState({ cart_list: cartListResp });
                        ls.set(lsShoppingCartCartList, cartListResp);
                      }
                      //= toast start
                      if (_.isString(response.data.status)) {
                        if (response.data.status === "OK") {
                          if (_.isString(response.data.message)) {
                            const { message } = response.data;
                            this.toastShow("success", message);
                          }
                        } else if (_.isString(response.data.message)) {
                          const { message } = response.data;
                          this.toastShow("error", message);
                        }
                      }
                      //= toast end
                      const cartEd = _.find(this.state.cart_list, {
                        cart_product_uxid: productBuy.product_uxid,
                      });
                      if (_.isObject(cartEd)) {
                        this.setState({
                          cartEdit: true,
                          cartEditData: cartEd,
                        });
                      }
                    }
                  });
              }
            } else {
              this.toastShow("error", "Tidak dapat beli produk ini");
            }
          }
        }
      } else {
        this.props.history.push("/login");
      }
    }
  };

  handleShoppingCartOpen = () => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      ls.set(lsShoppingCartTrxInfo, this.state.trx_info);
      ls.set(lsShoppingCartCartList, this.state.cart_list);
      this.props.history.push("/shoppingcart");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div style={{ paddingBottom: 56 }}>
          <div className={classes.page}>
            <div
              style={
                this.state.product_info.product_uxid ? {} : { display: "none" }
              }
            >
              <div style={{ height: 18 }}></div>
              <div style={{ paddingBottom: 56 }}>
                <div className={classes.content}>
                  <div className={classes.displayDesktop}>
                    <Typography
                      className={classes.textCenter}
                      variant="h6"
                      noWrap
                    >
                      {this.state.product_info.product_name}
                    </Typography>
                  </div>
                  <OlsProductImageSlider album={this.state.image_album} />
                  <Container>
                    <Typography className={classes.textCenter} variant="h6">
                      Harga :{" "}
                      <NumberFormat
                        value={this.state.product_info.product_sale_price}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                        thousandSeparator="."
                        decimalSeparator=","
                      />{" "}
                      / {this.state.product_info.product_unit}
                    </Typography>
                    {this.state.product_info.product_discount_value > 0 && (
                      <Divider />
                    )}
                    {this.state.product_info.product_discount_value > 0 && (
                      <Typography
                        className={classes.textCenter}
                        variant="subtitle2"
                      >
                        Diskon :{" "}
                        <NumberFormat
                          className={classes.discount}
                          value={this.state.product_info.product_discount_value}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                        {this.state.product_info.product_discount_type ===
                        "PERCENTAGE"
                          ? " %"
                          : ""}
                      </Typography>
                    )}
                    <Divider />
                    <Button
                      className={classes.buttonStyle}
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={this.handleClickBuy}
                    >
                      BELI
                    </Button>
                    {this.state.product_info.product_detail > 0 && <Divider />}
                    <Typography
                      className={classes.justifyCenter}
                      variant="body1"
                    >
                      {this.state.product_info.product_detail}
                    </Typography>
                  </Container>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <OlsFooter outlet={this.state.otl_info} />
        </div>

        <OlsNavbarProduct
          value=" "
          title={this.state.product_info.product_name}
          text="Hallo"
          outlet={this.state.otl_info}
          cartCount={_.size(this.state.cart_list)}
          onCartClick={this.handleShoppingCartOpen}
          onBackClick={this.handleGoBack}
        />

        <div className={classes.displayMobile}>
          <WhatsAppLink
            phone={this.state.otl_info.otl_whatsapp}
            label="Butuh Bantuan ?"
            text="Bantu Cara Transaksi"
          />
          <OlsBottomNav />
        </div>

        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>

        <OlsCartEdit
          data={this.state.cartEditData}
          onClose={this.handleCartEditClose}
          onSave={this.handleCartEditSave}
          onDelete={this.handleCartEditDelete}
          open={this.state.cartEdit}
        />
        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          severity={this.state.toastSeverity}
          onClose={this.toastOnClose}
        />
      </Fragment>
    );
  }
}

export default withStyles(useStyles)(Product);
