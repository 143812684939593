/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "typeface-roboto";

const useStyles = makeStyles((theme) => ({
  dialogContentStyle: {
    scrollbarWidth: 0,
    padding: "20px 30px",
    minHeight: 100,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default function Confirm(props) {
  const classes = useStyles();
  const { open, text, onClose, onYes } = props;

  const handleClose = () => {
    onClose();
  };

  const handleYes = () => {
    onYes();
  };

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      fullWidth
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.textCenter} id="simple-dialog-title">
        Konfirmasi
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <div style={{ paddingTop: 25, paddingBottom: 25 }}>
          <Typography className={classes.textCenter} variant="h6">
            {text}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonActionsStyle}>
        <Button
          className={classes.buttonStyle}
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          TIDAK
        </Button>
        <Button
          className={classes.buttonStyle}
          onClick={handleYes}
          color="primary"
          variant="contained"
          autoFocus
        >
          YA
        </Button>
      </DialogActions>
    </Dialog>
  );
}
