/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import "../../config";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import Divider from "@material-ui/core/Divider";
import OlsFooter from "../../component/OlsFooter/OlsFooter";
import OlsNavbarAkun from "../../component/OlsNavbar/OlsNavbarAkun";
import UsrAlamatLengkap from "../../container/UsrAlamatLengkap/UsrAlamatLengkap";
import UsrRealname from "../../component/OlsRow/UsrRealname";
import UsrChangePassword from "../../component/OlsRow/UsrChangePassword";
import UsrWhatsApp from "../../component/OlsRow/UsrWhatsApp";
import UsrPoint from "../../component/OlsRow/UsrPoint";
import UsrQRCode from "../../component/OlsRow/UsrQRCode";
import UsrLogout from "../../component/OlsRow/UsrLogout";

let isMounted = false;

const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    paddingTop: 62,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  content: {
    padding: 4,
    display: "block",
    minHeight: 1000,
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

class Akun extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usr_info: ls.get("usr_info") || false,
      otl_info: ls.get(`otl_info`) || {},
      trx_info: ls.get(`otl_ShoppingCart_TrxInfo`) || {},
      cart_list: [],
      loading: false,
    };
  }

  componentDidMount() {
    isMounted = true;

    let otl_info = ls.get(`otl_info`) || {};
    this.setState({
      otl_info: otl_info,
    });
    if (_.isString(otl_info.otl_name)) {
      document.title = otl_info.otl_name;
    }

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });

    this.pageInfo();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    isMounted = false;
  }

  pageInfo = () => {
    this.setState({ loading: true });
    const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
    const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios
      .post(base_url + "/olshop_api/page/info/", formdatau)
      .then((response) => {
        if (isMounted) {
          this.setState({ loading: false });
          if (_.isObject(response.data.usr_info)) {
            this.setState({ usr_info: response.data.usr_info });
            ls.set("usr_info", response.data.usr_info);
            this.usrInfo();
          } else {
            this.setState({ usr_info: false });
            ls.set("usr_info", false);
            this.props.history.push("/login");
          }
          if (_.isObject(response.data.otl_info)) {
            let otl_info = response.data.otl_info;
            this.setState({
              otl_info: otl_info,
            });
            ls.set(`otl_info`, otl_info);
            if (_.isString(otl_info.otl_name)) {
              document.title = otl_info.otl_name;
            }
          }
          if (_.isArray(response.data.cart_list)) {
            const cartListResp = response.data.cart_list;
            this.setState({ cart_list: cartListResp });
            ls.set(lsShoppingCartCartList, cartListResp);
          }
          if (_.isObject(response.data.trx_info)) {
            const trxInfoResp = response.data.trx_info;
            if (_.isString(trxInfoResp.trx_uxid)) {
              if (trxInfoResp.trx_status !== "TEMP") {
                ls.set(lsShoppingCartTrxInfo, {});
                ls.set(lsShoppingCartCartList, []);
                this.setState({ trx_info: {} });
                this.setState({ cart_list: [] });
              } else {
                this.setState({ trx_info: trxInfoResp });
                ls.set(lsShoppingCartTrxInfo, trxInfoResp);
              }
            }
          }
        }
      });
  };

  usrInfo = () => {
    this.setState({ loading: true });
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios
      .post(base_url + "/olshop_api/acc/acc_usr_info/", formdatau)
      .then((response) => {
        if (isMounted) {
          this.setState({ loading: false });
          if (_.isObject(response.data.usr_info)) {
            this.setState({ usr_info: response.data.usr_info });
            ls.set("usr_info", response.data.usr_info);
          }
        }
      });
  };

  handleShoppingCartOpen = () => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      ls.set(lsShoppingCartTrxInfo, this.state.trx_info);
      ls.set(lsShoppingCartCartList, this.state.cart_list);
      this.props.history.push("/shoppingcart");
    }
  };

  handleAddressChange = (dt) => {
    if (isMounted) {
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("usr_realname", dt.usr_realname);
      formdata.append("usr_addr1", dt.usr_addr1);
      formdata.append("usr_addr2", dt.usr_addr2);
      formdata.append("usr_addr3", dt.usr_addr3);
      formdata.append("usr_addr4", dt.usr_addr4);
      formdata.append("usr_addr5", dt.usr_addr5);
      axios
        .post(base_url + "/olshop_api/acc/acc_addr_update/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isObject(response.data.usr_info)) {
              this.setState({ usr_info: response.data.usr_info });
              ls.set("usr_info", this.state.usr_info);
            }
          }
        });
    }
  };

  handleWhatsAppChange = (dt) => {
    if (isMounted) {
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("usr_phone", dt.usr_phone);
      axios
        .post(base_url + "/olshop_api/acc/acc_phone_update/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isObject(response.data.usr_info)) {
              this.setState({ usr_info: response.data.usr_info });
              ls.set("usr_info", this.state.usr_info);
            }
          }
        });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingBottom: 56, minHeight: 1000 }}>
        <div style={this.state.usr_info ? {} : { display: "none" }}>
          <div className={classes.page}>
            <div className={classes.displayDesktop}>
              <div style={{ height: 5 }}></div>
            </div>

            <div style={this.state.usr_info ? {} : { display: "none" }}>
              <div style={{ paddingTop: 5, paddingBottom: 56 }}>
                <div className={classes.content}>
                  <UsrRealname data={this.state.usr_info} />
                  <UsrQRCode data={this.state.usr_info} />
                  <UsrPoint data={this.state.usr_info} />
                  <UsrAlamatLengkap
                    data={this.state.usr_info}
                    onChange={this.handleAddressChange}
                  />
                  <UsrWhatsApp
                    data={this.state.usr_info}
                    onChange={this.handleWhatsAppChange}
                  />
                  <UsrChangePassword />
                  <UsrLogout />
                </div>
              </div>
            </div>
          </div>

          <Divider />
          <OlsFooter outlet={this.state.otl_info} />
        </div>

        <OlsNavbarAkun
          value="akun"
          title="Akun"
          text={
            this.state.otl_info.otl_name
              ? `Hallo ${this.state.otl_info.otl_name}`
              : "Hallo"
          }
          outlet={this.state.otl_info}
          cartCount={_.size(this.state.cart_list)}
          onCartClick={this.handleShoppingCartOpen}
        />

        <div className={classes.displayMobile}>
          <OlsBottomNav value="akun" />
        </div>
        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Akun);
