/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Fragment, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import { Divider } from "@material-ui/core";
import "typeface-roboto";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import NumberFormat from "react-number-format";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Grid from "@material-ui/core/Grid";
import { Parser } from "html-to-react";
import Toast from "../../component/Toast/Toast";

let isDlgChannelMounted = false;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 4,
  },
  cardWrapper: {
    marginBottom: 5,
  },
  dialogContentStyle: {
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonStyle: {
    margin: "0px",
    borderRadius: 0,
    fontWeight: "bold",
  },
  buttonSecondary: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  fullHeight: {
    height: "100%",
  },
  headerCartWrapper: {
    padding: "4px 0px 2px 0px",
  },
  headerCartContent: {
    borderRadius: "15px 15px 0px 0px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    padding: 16,
    boxShadow: "1px 3px 1px #9E9E9E",
  },
  step: {
    padding: "0px 10px 0px 0px",
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 5,
  },
}));

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

const DialogChannel = (props) => {
  const classes = useStyles();
  const [channels, setChannels] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const { onClose, onSelect, open } = props;

  const doLoad = () => {
    if (open) {
      if (isDlgChannelMounted) {
        setLoading(true);
        const formdatac = new FormData();
        formdatac.append("last_count", 0);
        axios
          .post(base_url + "/olshop_api/bayar/bayar_channel_list/", formdatac)
          .then((response) => {
            if (isDlgChannelMounted) {
              setLoading(false);
              if (_.isArray(response.data.channel_list)) {
                setChannels(response.data.channel_list);
              }
            }
          });
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (selected) => {
    onSelect(selected);
  };

  useEffect(doLoad, [open]);

  useEffect(
    () => () => {
      // component unmount
      isDlgChannelMounted = false;
    },
    []
  );

  useEffect(() => () => {
    // component mount
    isDlgChannelMounted = true;
  });

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.titleStyle} id="simple-dialog-title">
        METODE PEMBAYARAN
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <div>
          <div>
            <List>
              {channels.map((channel, index) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(channel)}
                  key={index}
                >
                  <ListItemText primary={channel.name} />
                </ListItem>
              ))}
            </List>
          </div>
          <div style={{ height: 5 }}>
            <LinearProgress style={loading ? {} : { display: "none" }} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const TrxBayar = (props) => {
  const classes = useStyles();

  const { trx, bayar, onTrxUpdate, onBayarUpdate } = props;
  const [openChannel, setOpenChannel] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [toastOpen, setToastOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastSeverity, setToastSeverity] = React.useState("");

  const toastShow = (severity, message) => {
    setToastOpen(true);
    setToastMessage(message);
    setToastSeverity(severity);
  };

  const toastOnClose = () => {
    setToastOpen(false);
  };

  const handleOnChannelSelect = (channel) => {
    setOpenChannel(false);
    setLoading(true);
    const formdata = new FormData();
    formdata.append("bayar_method", channel.code);
    formdata.append("trx_uxid", trx.trx_uxid);
    axios
      .post(base_url + "/olshop_api/bayar/bayar_add/", formdata)
      .then((response) => {
        setLoading(false);
        if (_.isObject(response.data.bayar_info)) {
          onBayarUpdate(response.data.bayar_info);
        }
      });
  };

  const handleConfirm = (channel) => {
    if (!loading) {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("bayar_uxid", bayar.bayar_uxid);
      axios
        .post(base_url + "/olshop_api/bayar/bayar_confirm/", formdata)
        .then((response) => {
          setLoading(false);
          if (_.isObject(response.data.bayar_info)) {
            onBayarUpdate(response.data.bayar_info);
            if (_.isObject(response.data.trx_info)) {
              onTrxUpdate(response.data.trx_info);
            }
            //= toast start
            if (_.isString(response.data.status)) {
              if (response.data.status === "OK") {
                if (_.isString(response.data.message)) {
                  const { message } = response.data;
                  toastShow("success", message);
                }
              } else if (_.isString(response.data.message)) {
                const { message } = response.data;
                toastShow("error", message);
              }
            }
            //= toast end
          }
        });
    }
  };

  const handleChannelOpen = () => {
    if (!loading) {
      setOpenChannel(true);
    }
  };

  const handleOnChannelClose = () => {
    setOpenChannel(false);
  };

  const handleCopyRek = () => {
    toastShow("success", "Nomor Rekening dicopy ke Clipboard");
  };

  const handleCopyNilai = () => {
    toastShow("success", "Nilai Transfer dicopy ke Clipboard");
  };

  return (
    <div className={classes.root}>
      {bayar ? (
        <Fragment>
          {bayar.bayar_status === "PAID" ? (
            <Card className={classes.cardWrapper}>
              <CardContent>
                <Typography className={classes.label} variant="h6">
                  DIBAYAR
                </Typography>
              </CardContent>
            </Card>
          ) : (
            <div>
              <div className={classes.headerCartWrapper}>
                <div className={classes.headerCartContent}>
                  <Typography variant="h6" noWrap>
                    Pembayaran
                  </Typography>
                </div>
              </div>
              <Card className={classes.cardWrapper}>
                <CardContent>
                  <Grid container spacing={2} justify="space-between">
                    <Grid item>
                      <Grid
                        item
                        className={classes.fullHeight}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography variant="subtitle1">
                            {bayar.bayar_payment_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        item
                        className={classes.fullHeight}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <Grid item>
                          <CopyToClipboard
                            text={bayar.bayar_pay_code}
                            onCopy={handleCopyRek}
                          >
                            <Typography
                              variant="body1"
                              style={{ minHeight: 23 }}
                            >
                              {bayar.bayar_pay_code}
                            </Typography>
                          </CopyToClipboard>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2} justify="space-between">
                    <Grid item>
                      <Grid
                        item
                        className={classes.fullHeight}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography variant="subtitle1">
                            Biaya Transfer
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        item
                        className={classes.fullHeight}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <Grid item>
                          <Typography variant="body1" style={{ minHeight: 23 }}>
                            <NumberFormat
                              value={bayar.bayar_total_fee}
                              isNumericString
                              displayType="text"
                              decimalScale={0}
                              thousandSeparator="."
                              decimalSeparator=","
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2} justify="space-between">
                    <Grid item>
                      <Grid
                        item
                        className={classes.fullHeight}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography variant="subtitle1">
                            Nilai Yang Ditransfer
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        item
                        className={classes.fullHeight}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <Grid item>
                          <CopyToClipboard
                            text={bayar.bayar_amount}
                            onCopy={handleCopyNilai}
                          >
                            <Typography
                              variant="body1"
                              style={{ minHeight: 23 }}
                            >
                              <NumberFormat
                                value={bayar.bayar_amount}
                                isNumericString
                                displayType="text"
                                decimalScale={0}
                                thousandSeparator="."
                                decimalSeparator=","
                              />
                            </Typography>
                          </CopyToClipboard>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container spacing={2} justify="space-between">
                    <Grid item>
                      <Grid
                        item
                        className={classes.fullHeight}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-start"
                      >
                        <Grid item>
                          <Typography variant="subtitle1">
                            Transfer Sebelum
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        item
                        className={classes.fullHeight}
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                      >
                        <Grid item>
                          <Typography
                            style={{ color: "blue", fontWeight: "bold" }}
                            variant="subtitle1"
                          >
                            {bayar.bayar_expired_date_time}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                </CardContent>
              </Card>
              <Card className={classes.cardWrapper}>
                <CardContent>
                  <div style={{ height: 5 }}>
                    <LinearProgress
                      style={loading ? {} : { display: "none" }}
                    />
                  </div>
                  <div>
                    <Button
                      className={classes.buttonStyle}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={handleConfirm}
                    >
                      KONFIRMASI
                    </Button>
                  </div>
                </CardContent>
              </Card>
              <div className={classes.headerCartWrapper}>
                <div className={classes.headerCartContent}>
                  <Typography variant="h6" noWrap>
                    Cara Pembayaran
                  </Typography>
                </div>
              </div>
              <Card className={classes.cardWrapper}>
                <CardContent>
                  {bayar.bayar_instructions_array.map((instruction, index) => (
                    <Fragment>
                      <Typography
                        variant="h6"
                        style={{ minHeight: 23, fontWeight: "bold" }}
                      >
                        {instruction.title}
                      </Typography>
                      <Divider />
                      {instruction.steps.map((step, index) => (
                        <Fragment>
                          <Typography variant="body1" style={{ minHeight: 23 }}>
                            {index + 1}
                            {". "}
                            {Parser().parse(step)}
                          </Typography>
                          <Divider />
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </CardContent>
              </Card>
            </div>
          )}
        </Fragment>
      ) : (
        <Card className={classes.cardWrapper}>
          <CardContent>
            <div style={{ height: 5 }}>
              <LinearProgress style={loading ? {} : { display: "none" }} />
            </div>
            <div>
              <Button
                className={classes.buttonStyle}
                fullWidth
                variant="contained"
                color="secondary"
                onClick={handleChannelOpen}
              >
                BAYAR
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
      <DialogChannel
        open={openChannel}
        onSelect={handleOnChannelSelect}
        onClose={handleOnChannelClose}
      />
      <Toast
        open={toastOpen}
        message={toastMessage}
        severity={toastSeverity}
        onClose={toastOnClose}
      />
    </div>
  );
};

export default TrxBayar;
