/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import "../../config";
import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "typeface-roboto";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import axios from "axios";
import ls from "local-storage";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import VisibilitySensor from "react-visibility-sensor";
import LinearProgress from "@material-ui/core/LinearProgress";

let isDlgCategoryMounted = false;

const useStyles = makeStyles((theme) => ({
  buttonCategory: {
    margin: 0,
    borderRadius: 0,
    padding: 15,
    fontWeight: "bold",
  },
  cardWrapper: {
    marginBottom: 5,
  },
  dialogContentStyle: {
    minHeight: 350,
    maxHeight: 350,
    marginBottom: 30,
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  searchPaper: {
    padding: "2px 4px",
    marginTop: "6px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  searchInput: {
    marginLeft: 1,
    flex: 1,
  },
  searchIconButton: {
    padding: 10,
  },
  searchLoading: {
    margin: "2px 10px",
    padding: 2,
  },
  content: {
    display: "block",
    minHeight: 600,
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
  },
  linkNotActive: {
    color: theme.palette.primary.main,
  },
}));

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}
let search = "";

const DialogCategory = (props) => {
  const classes = useStyles();
  const [categorys, setCategorys] = React.useState(
    ls.get(`otl_category_list`) || []
  );
  const [searchText, setSearchText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [more, setMore] = React.useState(false);

  const { onClose, onSelect, open } = props;

  const doLoad = () => {
    if (open) {
      if (isDlgCategoryMounted) {
        setLoading(true);
        const formdatac = new FormData();
        formdatac.append("last_count", 0);
        axios
          .post(
            base_url + "/olshop_api/product/product_category_list/",
            formdatac
          )
          .then((response) => {
            if (isDlgCategoryMounted) {
              setLoading(false);
              if (_.isArray(response.data.category_list)) {
                setCategorys(response.data.category_list);
                ls.set(`otl_category_list`, response.data.category_list);
                if (_.size(response.data.category_list) > 0) {
                  setMore(true);
                } else {
                  setMore(false);
                }
              }
            }
          });
      }
    }
  };

  const doLoadMore = () => {
    if (isDlgCategoryMounted) {
      const lastCount = _.size(categorys);
      setLoading(true);
      const formdatac = new FormData();
      formdatac.append("last_count", lastCount);
      axios
        .post(
          base_url + "/olshop_api/product/product_category_list/",
          formdatac
        )
        .then((response) => {
          if (isDlgCategoryMounted) {
            setLoading(false);
            if (_.isArray(response.data.category_list)) {
              if (_.size(response.data.category_list) > 0) {
                const categoryNew = _.union(
                  categorys,
                  response.data.category_list
                );
                setCategorys(categoryNew);
                setMore(true);
              } else {
                setMore(false);
              }
            }
          }
        });
    }
  };

  const doSearch = () => {
    if (isDlgCategoryMounted) {
      setLoading(true);
      const formdatac = new FormData();
      formdatac.append("search", search);
      formdatac.append("last_count", 0);
      axios
        .post(
          base_url + "/olshop_api/product/product_category_search/",
          formdatac
        )
        .then((response) => {
          if (isDlgCategoryMounted) {
            setLoading(false);
            if (_.isArray(response.data.category_list)) {
              setCategorys(response.data.category_list);
              if (_.size(response.data.category_list) > 0) {
                setMore(true);
              } else {
                setMore(false);
              }
            }
          }
        });
    }
  };

  const doSearchMore = () => {
    if (isDlgCategoryMounted) {
      const lastCount = _.size(categorys);
      setLoading(true);
      const formdatac = new FormData();
      formdatac.append("search", search);
      formdatac.append("last_count", lastCount);
      axios
        .post(
          base_url + "/olshop_api/product/product_category_search/",
          formdatac
        )
        .then((response) => {
          if (isDlgCategoryMounted) {
            setLoading(false);
            if (_.isArray(response.data.category_list)) {
              if (_.size(response.data.category_list) > 0) {
                const categoryNew = _.union(
                  categorys,
                  response.data.category_list
                );
                setCategorys(categoryNew);
                setMore(true);
              } else {
                setMore(false);
              }
            }
          }
        });
    }
  };

  const handleSearchOnChange = (event) => {
    search = event.target.value;
    setSearchText(search);
    doSearch();
  };
  const handleSearchKeyPress = (event) => {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
      search = event.target.value;
      setSearchText(search);
      doSearch();
    }
  };
  const handleSearchClear = () => {
    search = "";
    setSearchText(search);
    doLoad();
  };

  const handleClose = () => {
    search = "";
    setSearchText(search);
    onClose();
  };

  const handleListItemClick = (selected) => {
    search = "";
    setSearchText(search);
    onSelect(selected);
  };

  const handleLoadMore = (isMore) => {
    setMore(false);
    if (isMore) {
      if (_.isEmpty(search)) {
        doLoadMore();
      } else {
        doSearchMore();
      }
    }
  };

  useEffect(doLoad, [open]);

  useEffect(
    () => () => {
      // component unmount
      isDlgCategoryMounted = false;
    },
    []
  );

  useEffect(() => () => {
    // component mount
    isDlgCategoryMounted = true;
  });

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.titleStyle} id="simple-dialog-title">
        PILIH KATEGORI
      </DialogTitle>
      <Paper component="form" className={classes.searchPaper}>
        <IconButton
          className={classes.searchIconButton}
          onClick={handleSearchClear}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          autoFocus
          className={classes.searchInput}
          placeholder="Cari kategori..."
          value={searchText}
          onChange={handleSearchOnChange}
          onKeyPress={handleSearchKeyPress}
        />
        <CircularProgress
          className={classes.searchLoading}
          style={loading ? {} : { display: "none" }}
        />
      </Paper>
      <DialogContent className={classes.dialogContentStyle}>
        <div>
          <div className={classes.content}>
            <List>
              <ListItem button onClick={handleClose}>
                <ListItemText primary="SEMUA PRODUK" />
              </ListItem>
              {categorys.map((category, index) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(category)}
                  key={index}
                >
                  <ListItemText primary={category} />
                </ListItem>
              ))}
            </List>
          </div>
          <VisibilitySensor onChange={handleLoadMore}>
            <div style={{ height: 5 }}>
              <LinearProgress style={more ? {} : { display: "none" }} />
            </div>
          </VisibilitySensor>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const OlsCategorySelect = (props) => {
  const classes = useStyles();
  const { type, onSelect } = props;
  const [openCategory, setOpenCategory] = React.useState(false);
  const [selectedCategory, setSelectedCategory] =
    React.useState("PILIH KATEGORI");

  const handleClickOpen = () => {
    setOpenCategory(true);
  };

  const handleOnCategorySelect = (category) => {
    setOpenCategory(false);
    setSelectedCategory(category);
    onSelect(category);
  };
  const handleOnCategoryClose = () => {
    setOpenCategory(false);
    setSelectedCategory("PILIH KATEGORI");
    onSelect("");
  };

  return (
    <Fragment>
      {type === "label" ? (
        <Button
          color="primary"
          type="submit"
          fullWidth
          onClick={handleClickOpen}
        >
          {selectedCategory}
        </Button>
      ) : (
        <Button
          className={classes.buttonCategory}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
        >
          {selectedCategory}
        </Button>
      )}
      <DialogCategory
        open={openCategory}
        onSelect={handleOnCategorySelect}
        onClose={handleOnCategoryClose}
      />
    </Fragment>
  );
};

export default OlsCategorySelect;
