/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import Truncate from "react-truncate";
import NumberFormat from "react-number-format";
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles((theme) => ({
  desktop: {
    width: "25%",
    float: "left",
    padding: 4,
  },
  smartphone: {
    width: "50%",
    float: "left",
    padding: 4,
  },
  media: {
    width: "100%",
    minHeight: "152px",
    borderRadius: 10,
  },
  title: {
    color: theme.palette.primary.main,
  },
  discount: {
    position: "absolute",
    top: 10,
    backgroundColor: "red",
    padding: 5,
    color: "white !important",
    borderRadius: 20,
    minWidth: 50,
    textAlign: "center",
    boxShadow: "1px 3px 1px #9E9E9E",
    fontWeight: "bold",
  },
}));

const OlsProductGrid = (props) => {
  const classes = useStyles();
  const { data, onSelect } = props;

  const isMobileScreen = useMediaQuery({ query: "(max-width: 480px)" });

  let discountStr = "";
  if (data.product_discount_value > 0) {
    if (data.product_discount_type === "PERCENTAGE") {
      discountStr = " %";
    }
  }

  return (
    <div className={isMobileScreen ? classes.smartphone : classes.desktop}>
      <Card onClick={() => onSelect(data)}>
        <CardActionArea>
          <CardContent>
            <img
              alt=" "
              className={classes.media}
              src={data.product_image_url}
            />
            {data.product_discount_value > 0 && (
              <div className={classes.discount}>
                <Typography variant="body2">
                  <NumberFormat
                    value={data.product_discount_value}
                    isNumericString
                    displayType="text"
                    decimalScale={0}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                  {discountStr}
                </Typography>
              </div>
            )}
            <Typography
              className={classes.title}
              gutterBottom
              variant="body1"
              component="h2"
              noWrap
            >
              <Truncate lines={1} ellipsis="..">
                {data.product_name}
              </Truncate>
            </Typography>
            <Typography variant="body1" color="textSecondary" component="h2">
              <NumberFormat
                value={data.product_sale_price}
                isNumericString
                displayType="text"
                decimalScale={0}
                thousandSeparator="."
                decimalSeparator=","
              />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

OlsProductGrid.defaultProps = {
  data: {
    product_uxid: "",
    product_name: "",
    product_code: "",
    product_detail: "",
    product_unit: "",
    product_sale_price: 0,
    product_discount_type: "",
    product_discount_value: 0,
    product_stk_qty: 0,
    product_image_url: "",
  },
};

export default OlsProductGrid;
