/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SortByIcon = (props) => {
  const classes = useStyles();
  const {value, onSort} = props;
  const [sort, setSort] = React.useState('ALPHABET');

  const handleChange = (event) => {
    setSort(event.target.value);
    if(event.target.value==='BEST'){
      onSort(true);
    } else {
      onSort(false);
    }
  };

  useEffect(() => {
    if (value) {
      setSort('BEST');
    } else {
      setSort('ALPHABET');
    }
  }, [value]);

  return (
    <FormControl className={classes.formControl}>
        <Select
          value={sort}
          onChange={handleChange}
        >
          <MenuItem value={'ALPHABET'}>ALPHABET</MenuItem>
          <MenuItem value={'BEST'}>TERLARIS</MenuItem>
        </Select>
      </FormControl>
  );
};

export default SortByIcon;
