/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles({
  sliderWrapper: {
    marginTop: 62,
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .carousel ul': {
      paddingInlineStart: '0px',
    },
  },
  sliderImage: {
    backgroundColor: 'white',
  },
});

const OlsProductImageSlider = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <div className={classes.sliderWrapper}>
      <Carousel autoPlay infiniteLoop showArrows={true} showStatus={false} showThumbs={false}>
        {
          data.map((imgsrc, index) => <img key={index} className={classes.sliderImage} alt="" src={imgsrc} />)
        }
      </Carousel>
    </div>
  );
};

export default OlsProductImageSlider;
