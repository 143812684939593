import React from "react";
import {
  makeStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "../Home/Home";
import Trx from "../Trx/Trx";
import TrxView from "../TrxView/TrxView";
import OlsLogin from "../OlsLogin/OlsLogin";
import OlsRegister from "../OlsRegister/OlsRegister";
import Product from "../Product/Product";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import Akun from "../Akun/Akun";
import Products from "../Products/Products";
import Ketentuan from "../Home/Ketentuan";
import Kebijakanprivasi from "../Home/Kebijakanprivasi";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: window._DEFAULT_COLOR,
    },
    secondary: {
      main: "#E33E7F",
    },
    color: {
      background: "#fafafa",
      footer: "#dedede",
    },
    width: {
      maxWidth: "100%",
      appWidth: "1188px",
    },
  },
});

const useStyles = makeStyles(() => ({
  page: {
    padding: 0,
    maxWidth: theme.palette.width.maxWidth,
    display: "flex",
    flexDirection: "column",
  },
}));

const Main = () => {
  const classes = useStyles();
  //const basePath = window.location.pathname;
  //console.log(basePath);
  const basePath = "/";

  return (
    <BrowserRouter basename={basePath}>
      <MuiThemeProvider theme={theme}>
        <Container className={classes.page}>
          <CssBaseline />
          <Route path="/" exact component={Home} />
          <Route path="/login" component={OlsLogin} />
          <Route path="/register" component={OlsRegister} />
          <Route path="/home" component={Home} />
          <Route path="/products" component={Products} />
          <Route path="/trx" exact component={Trx} />
          <Route path="/trxview/:uxid" component={TrxView} />
          <Route path="/akun" exact component={Akun} />
          <Route path="/product/:uxid" component={Product} />
          <Route path="/shoppingcart" exact component={ShoppingCart} />
          <Route path="/ketentuan" exact component={Ketentuan} />
          <Route path="/kebijakanprivasi" exact component={Kebijakanprivasi} />
        </Container>
      </MuiThemeProvider>
    </BrowserRouter>
  );
};

export default Main;
