/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import "../../config";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  OlsTaxRow,
  OlsBulatRow,
  OlsShippingRow,
  OlsTotalRow,
} from "../../component/OlsRow";
import OlsCartRow from "../../component/OlsCartRow/OlsCartRow";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import Toast from "../../component/Toast/Toast";
import Confirm from "../../component/Confirm/Confirm";
import OlsRejectedRow from "../../component/OlsRow/OlsRejectedRow";
import WhatsAppLink from "../../component/WhatsAppLink/WhatsAppLink";
import OlsFooter from "../../component/OlsFooter/OlsFooter";
import OlsNavbarTrxView from "../../component/OlsNavbar/OlsNavbarTrxView";
import Divider from "@material-ui/core/Divider";
import TrxAlamat from "../../container/TrxAlamatLengkap/TrxAlamat";
import TrxBayar from "../../container/TrxBayar/TrxBayar";
import OlsPaidRow from "../../component/OlsRow/OlsPaidRow";

let isMounted = false;

const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    paddingTop: 62,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  content: {
    padding: 4,
    display: "block",
    minHeight: 1000,
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
  },
  contentCartWrapper: {
    marginTop: 0,
    padding: 0,
    display: "block",
    width: "100%",
  },
  headerCartWrapper: {
    padding: "4px 4px 2px 4px",
  },
  headerCartContent: {
    borderRadius: "15px 15px 0px 0px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    padding: 16,
    boxShadow: "1px 3px 1px #9E9E9E",
  },
  buttonStyle: {
    margin: "10px 0px",
    borderRadius: 0,
    padding: 15,
    fontWeight: "bold",
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

class TrxView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usr_info: ls.get("usr_info") || false,
      otl_info: ls.get(`otl_info`) || {},
      trx_info: ls.get(`otl_ShoppingCart_TrxInfo`) || {},
      cart_list: [],
      v_trx_info: {},
      v_cart_list: [],
      bayar_info: false,
      bank_list: [],
      loading: false,
      toastOpen: false,
      toastMessage: "",
      toastSeverity: "info",
      confirmDelete: false,
    };
  }

  componentDidMount() {
    isMounted = true;

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });

    if (usr_info === false) {
      this.props.history.push("/home");
    } else {
      let otl_info = ls.get(`otl_info`) || {};
      this.setState({
        otl_info: otl_info,
      });
      if (_.isString(usr_info.usr_uxid)) {
        const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
        const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
        const trxInfo = ls.get(lsShoppingCartTrxInfo);
        if (_.isObject(trxInfo)) {
          if (_.isString(trxInfo.trx_client_uxid)) {
            if (trxInfo.trx_client_uxid === usr_info.usr_uxid) {
              this.setState({ trx_info: trxInfo });
              const cartList = ls.get(lsShoppingCartCartList);
              if (_.isArray(cartList)) {
                this.setState({ cart_list: cartList });
              }
            }
          }
        }
      }
      this.otlTrxView();
    }
    window.scrollTo(0, 0);
  }

  otlTrxView = () => {
    if (_.isObject(this.props.match.params)) {
      if (_.isString(this.props.match.params.uxid)) {
        const { uxid } = this.props.match.params;
        this.setState({ loading: true });
        const formdata = new FormData();
        formdata.append("trx_uxid", uxid);
        axios
          .post(base_url + "/olshop_api/trx/trx_view/", formdata)
          .then((response) => {
            if (isMounted) {
              this.setState({ loading: false });
              if (_.isObject(response.data.trx_info)) {
                const trxInfoResp = response.data.trx_info;
                if (_.isString(trxInfoResp.trx_uxid)) {
                  this.setState({ v_trx_info: trxInfoResp });
                }

                if (_.isArray(response.data.cart_list)) {
                  const cartListResp = response.data.cart_list;
                  this.setState({ v_cart_list: cartListResp });
                }
                if (_.isArray(response.data.bank_list)) {
                  const bankistResp = response.data.bank_list;
                  this.setState({ bank_list: bankistResp });
                }
              }
              if (_.isObject(response.data.bayar_info)) {
                this.setState({ bayar_info: response.data.bayar_info });
              }

              //= toast start
              if (_.isString(response.data.status)) {
                if (response.data.status === "OK") {
                  if (_.isString(response.data.message)) {
                    const { message } = response.data;
                    this.toastShow("success", message);
                  }
                } else if (_.isString(response.data.message)) {
                  const { message } = response.data;
                  this.toastShow("error", message);
                }
              }
              //= toast end
            }
          });
      }
    }
  };

  componentWillUnmount() {
    isMounted = false;
  }

  handleShoppingCartOpen = () => {
    if (isMounted) {
      this.props.history.push("/shoppingcart");
    }
  };

  handleGoBack = () => {
    if (this.props.history.length > 0) {
      this.props.history.goBack();
    }
  };

  toastShow = (severity, message) => {
    this.setState({
      toastOpen: true,
      toastMessage: message,
      toastSeverity: severity,
    });
  };

  toastOnClose = () => {
    this.setState({
      toastOpen: false,
    });
  };

  handleTrxDelete = () => {
    this.setState({
      confirmDelete: true,
    });
  };

  confirmDeleteClose = () => {
    this.setState({
      confirmDelete: false,
    });
  };

  confirmDeleteYes = () => {
    if (isMounted) {
      this.setState({
        confirmDelete: false,
      });
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("trx_uxid", this.state.v_trx_info.trx_uxid);
      axios
        .post(base_url + "/olshop_api/trx/trx_delete/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            //= toast start
            if (_.isString(response.data.status)) {
              if (response.data.status === "OK") {
                if (this.props.history.length > 0) {
                  this.props.history.goBack();
                } else {
                  this.props.history.push("/trx");
                }
              } else if (_.isString(response.data.message)) {
                const { message } = response.data;
                this.toastShow("error", message);
              }
            }
            //= toast end
          }
        });
    }
  };

  trxUpdate = (trx) => {
    this.setState({ v_trx_info: trx });
  };
  bayarUpdate = (bayar) => {
    this.setState({ bayar_info: bayar });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {!_.isString(this.state.v_trx_info.trx_uxid) && (
          <div style={{ paddingBottom: 56 }}>
            <div className={classes.page}>
              <div style={{ height: 18 }}></div>
              <div style={{ paddingBottom: 56 }}>
                <div className={classes.content}>
                  <div style={this.state.loading ? { display: "none" } : {}}>
                    <div style={{ marginTop: "100px", textAlign: "center" }}>
                      <ShoppingBasketIcon
                        style={{
                          textAlign: "center",
                          color: "#808080",
                          fontSize: 140,
                        }}
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Typography
                        style={{ color: "#808080" }}
                        variant="h6"
                        noWrap
                      >
                        Tidak dapat menampilkan transaksi
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <OlsFooter outlet={this.state.otl_info} />
          </div>
        )}
        {_.isString(this.state.v_trx_info.trx_uxid) && (
          <div style={{ paddingBottom: 56 }}>
            <div className={classes.page}>
              <div style={{ height: 18 }}></div>
              <div style={{ paddingBottom: 56 }}>
                <div className={classes.content}>
                  <div className={classes.headerCartWrapper}>
                    <div className={classes.headerCartContent}>
                      <Typography variant="h6" noWrap>
                        Alamat
                      </Typography>
                    </div>
                  </div>
                  <TrxAlamat data={this.state.v_trx_info} />

                  <div className={classes.headerCartWrapper}>
                    <div className={classes.headerCartContent}>
                      <Typography variant="h6" noWrap>
                        Daftar Belanja
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.contentCartWrapper}>
                    {this.state.v_cart_list.map((cart, index) => (
                      <OlsCartRow key={index} data={cart} onOpen={() => {}} />
                    ))}
                  </div>

                  {this.state.v_trx_info.trx_tax_percent > 0 && (
                    <OlsTaxRow
                      percent={this.state.v_trx_info.trx_tax_percent}
                      value={this.state.v_trx_info.trx_tax_value}
                    />
                  )}
                  {this.state.v_trx_info.trx_shipping_cost > 0 && (
                    <OlsShippingRow
                      label="Biaya Pengiriman"
                      value={this.state.v_trx_info.trx_shipping_cost}
                    />
                  )}
                  {this.state.v_trx_info.trx_amal > 0 && (
                    <OlsBulatRow
                      label="Pembulatan / Titip amal"
                      value={this.state.v_trx_info.trx_amal}
                    />
                  )}

                  <OlsTotalRow
                    label="Jumlah Total"
                    value={this.state.v_trx_info.trx_total}
                  />

                  {this.state.v_trx_info.trx_status === "TAGIHAN" && (
                    <TrxBayar
                      trx={this.state.v_trx_info}
                      bayar={this.state.bayar_info}
                      onTrxUpdate={this.trxUpdate}
                      onBayarUpdate={this.bayarUpdate}
                    />
                  )}

                  {this.state.v_trx_info.trx_status === "DITOLAK" && (
                    <div>
                      <OlsRejectedRow />
                      <div>
                        <Button
                          className={classes.buttonStyle}
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={this.handleTrxDelete}
                        >
                          HAPUS
                        </Button>
                      </div>
                    </div>
                  )}

                  {(this.state.v_trx_info.trx_terms === "MENUNGGU" ||
                    this.state.v_trx_info.trx_terms === "DITOKO" ||
                    this.state.v_trx_info.trx_terms === "TAGIHAN") && (
                    <div>
                      <Button
                        className={classes.buttonStyle}
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={this.handleTrxDelete}
                      >
                        HAPUS
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <OlsFooter outlet={this.state.otl_info} />
            <Confirm
              open={this.state.confirmDelete}
              text="Yakin ingin hapus transaksi?"
              onClose={this.confirmDeleteClose}
              onYes={this.confirmDeleteYes}
            />
          </div>
        )}

        <OlsNavbarTrxView
          value="trx"
          title={
            this.state.v_trx_info.trx_number
              ? `${this.state.v_trx_info.trx_number}`
              : `Transaksi`
          }
          text={
            this.state.v_trx_info.trx_number
              ? `Nomor ${this.state.v_trx_info.trx_number} `
              : `Hallo`
          }
          outlet={this.state.otl_info}
          cartCount={_.size(this.state.cart_list)}
          onCartClick={this.handleShoppingCartOpen}
          onBackClick={this.handleGoBack}
        />

        <div className={classes.displayMobile}>
          <WhatsAppLink
            phone={this.state.otl_info.otl_whatsapp}
            label="Butuh Bantuan ?"
            text={
              this.state.v_trx_info.trx_number
                ? `Transaksi Nomor ${this.state.v_trx_info.trx_number} `
                : `Hallo`
            }
          />
          <OlsBottomNav />
        </div>

        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>

        <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          severity={this.state.toastSeverity}
          onClose={this.toastOnClose}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(TrxView);
