/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import IconNavHome from "@material-ui/icons/Home";
import IconNavBelanja from "@material-ui/icons/LocalMall";
import IconWork from "@material-ui/icons/Work";
import IconNavProfile from "@material-ui/icons/AccountBox";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  appBottomNav: {
    maxWidth: theme.palette.width.appWidth,
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: "auto",
    right: "auto",
  },
  navItem: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  navItemSelected: {
    color: "white",
    backgroundColor: theme.palette.primary.light,
    "& .MuiSvgIcon-root": {
      color: "white",
    },
  },
}));

export default function OlsBottomNav(props) {
  const classes = useStyles();
  const { value } = props;
  return (
    <div className={classes.appBottomNav}>
      <BottomNavigation
        classes={{ root: classes.root }}
        showLabels
        value={value}
      >
        <BottomNavigationAction
          value="home"
          component={Link}
          to="/home"
          classes={{ root: classes.navItem, selected: classes.navItemSelected }}
          label="Home"
          icon={<IconNavHome />}
        />
        <BottomNavigationAction
          value="products"
          component={Link}
          to="/products"
          classes={{ root: classes.navItem, selected: classes.navItemSelected }}
          label="Produk"
          icon={<IconWork />}
        />
        <BottomNavigationAction
          value="trx"
          component={Link}
          to="/trx"
          classes={{ root: classes.navItem, selected: classes.navItemSelected }}
          label="Belanja"
          icon={<IconNavBelanja />}
        />
        <BottomNavigationAction
          value="akun"
          component={Link}
          to="/akun"
          classes={{ root: classes.navItem, selected: classes.navItemSelected }}
          label="Akun"
          icon={<IconNavProfile />}
        />
      </BottomNavigation>
    </div>
  );
}
