/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import 'typeface-roboto';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1px 0px 5px 0px',
  },
  pointStyle: {
    textAlign: 'center',
  },
}));

const UsrPoint = (props) => {
  const classes = useStyles();
  const { data } = props;
  return (
    <div className={classes.root}>
      <Card>
      <CardHeader
        title="Poin Member"
        subheader="Poin dapat ditukarkan dengan hadiah"
      />
        <CardContent style={{ padding: 20 }}>
          <Typography variant="h1" className={classes.pointStyle} >
            <NumberFormat value={data.usr_point} isNumericString displayType="text" decimalScale={0} thousandSeparator="." decimalSeparator="," />
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default UsrPoint;
