/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  Chip: {
    borderRadius: 4,
    height:40,
    padding:6,
    fontWeight:'bold',
  },
  Facebook: {
    position: 'fixed',
    left: 'auto',
    right: theme.spacing(5),
    bottom: theme.spacing(10),
  },
}));

const FacebookLink = (props) => {
  const classes = useStyles();
  const {url} = props;

  return (
    <div className={classes.Facebook}>
      <a style={url.length > 2 ? { marginLeft: 30 } : { display: 'none' }} target="_blank" rel="noopener noreferrer" href={url}>
        <Chip className={classes.Chip}
          icon={<FacebookIcon />}
          label="Facebook"
          clickable
          color="primary"
          size="medium"
        />
      </a>
    </div>
  );
};

export default FacebookLink;
