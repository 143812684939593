/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import "../../config";
import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FullHeight from "react-full-height";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import BlinK from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import OlsNavbarDoor from "../../component/OlsNavbar/OlsNavbarDoor";
import WhatsAppLink from "../../component/WhatsAppLink/WhatsAppLink";

let isMounted = false;

const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    paddingTop: 62,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    padding: 15,
    margin: theme.spacing(3, 0, 2),
  },
  dialogContentStyle: {
    minHeight: 120,
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonSecondary: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  cardContent: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    padding: "4px 16px",
    "&:last-child": {
      paddingBottom: "4px",
    },
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

const LostPassword = (props) => {
  const { classes, onClose, open } = props;

  const [emailText, setEmailText] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const messageShow = (msg) => {
    setErrorMessage(msg);
  };

  const messageHide = () => {
    setErrorMessage("");
    setError(false);
  };

  const handleSubmit = () => {
    if (isMounted) {
      const emailValid = emailText.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      if (emailValid) {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("email", emailText);
        axios
          .post(base_url + "/olshop_api/acc/acc_password_lost/", formdata)
          .then((response) => {
            if (isMounted) {
              setLoading(false);
              if (_.isString(response.data.message)) {
                messageShow(response.data.message);
                if (_.isString(response.data.status)) {
                  if (response.data.status === "OK") {
                    setTimeout(() => {
                      messageHide();
                      onClose();
                    }, 2000);
                  } else {
                    setError(true);
                    setTimeout(() => {
                      messageHide();
                    }, 3000);
                  }
                }
              } else {
                messageShow("Error tidak diketahui");
                setError(true);
                setTimeout(() => {
                  messageHide();
                }, 3000);
              }
            }
          });
      } else {
        messageShow("Alamat email tidak valid");
        setError(true);
        setTimeout(() => {
          messageHide();
        }, 3000);
      }
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmailText(e.target.value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      fullWidth
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.textCenter} id="simple-dialog-title">
        Reset Password
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <div style={{ height: 5 }}>
          <LinearProgress style={loading ? {} : { display: "none" }} />
        </div>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Masukan Alamat Email"
          name="email"
          autoFocus
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          error={error}
        />
        <div style={{ minHeight: 30 }}>
          <Card style={errorMessage.length ? {} : { display: "none" }}>
            <CardContent className={classes.cardContent}>
              <Typography variant="subtitle2">{errorMessage}</Typography>
            </CardContent>
          </Card>
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonActionsStyle}>
        <Button onClick={handleClose} variant="contained">
          TUTUP
        </Button>
        <Button
          className={classes.buttonPrimary}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          autoFocus
        >
          RESET
        </Button>
      </DialogActions>
    </Dialog>
  );
};

class OlsLogin extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      usr_info: ls.get("usr_info") || false,
      otl_info: ls.get(`otl_info`) || {},
      lost: false,
      email: "",
      password: "",
      errorEmail: false,
      errorPassword: false,
      errorMessage: "Silahkan login untuk dapat melakukan transaksi",
      loading: false,
    };
  }

  componentDidMount() {
    isMounted = true;
    let otl_info = ls.get(`otl_info`) || {};
    this.setState({
      otl_info: otl_info,
    });
    if (_.isString(otl_info.otl_name)) {
      document.title = otl_info.otl_name;
    }

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });
    if (usr_info === false) {
      this.pageInfo();
    } else {
      this.props.history.push("/home"); // jika sudah login redirect ke home
    }
  }

  componentDidUpdate() {
    // console.log(this.state);
  }

  componentWillUnmount() {
    isMounted = false;
  }

  handleChange = (e) => {
    if (e.target.name === "email") {
      this.setState({ email: e.target.value });
    }
    if (e.target.name === "password") {
      this.setState({ password: e.target.value });
    }
  };

  handleKeyPress = (event) => {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  messageShow = (msg) => {
    this.setState({ errorMessage: msg });
  };

  handleSubmit = () => {
    if (isMounted) {
      const emailValid = this.state.email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      if (emailValid) {
        this.setState({ errorEmail: false });
        if (this.state.password.length > 0) {
          this.setState({ errorPassword: false });
          if (!this.state.loading) {
            this.messageShow("Mohon tunggu proses login sedang berlangsung");
            this.setState({ loading: true });
            let key = ls.get("usr_start") || " ";
            let loginEmail = this.state.email; //Aes.Ctr.encrypt(this.state.email, key, 256);
            let loginPassword = this.state.password; //Aes.Ctr.encrypt(this.state.password, key, 256);
            const formdata = new FormData();
            formdata.append("email", loginEmail);
            formdata.append("password", loginPassword);
            axios
              .post(base_url + "/olshop_api/acc/acc_login/", formdata)
              .then((response) => {
                if (isMounted) {
                  this.setState({ loading: false });
                  if (_.isString(response.data.status)) {
                    if (response.data.status === "OK") {
                      if (_.isObject(response.data.usr_info)) {
                        this.setState({ usr_info: response.data.usr_info });
                        ls.set("usr_info", response.data.usr_info);
                        this.props.history.push("/home");
                      } else {
                        this.setState({ usr_info: false });
                        ls.set("usr_info", false);

                        this.messageShow("Error tidak diketahui");
                        this.setState({ errorEmail: true });
                        this.setState({ errorPassword: true });
                      }
                    } else {
                      this.setState({ usr_info: false });
                      ls.set("usr_info", false);

                      if (_.isString(response.data.message)) {
                        this.messageShow(response.data.message);
                      } else {
                        this.messageShow("Error tidak diketahui");
                      }
                      this.setState({ errorEmail: true });
                      this.setState({ errorPassword: true });
                    }
                  } else {
                    this.setState({ usr_info: false });
                    ls.set("usr_info", false);

                    this.messageShow("Error tidak diketahui");
                    this.setState({ errorEmail: true });
                    this.setState({ errorPassword: true });
                  }
                }
              });
          }
        } else {
          this.messageShow("Silahkan isi password");
          this.setState({ errorPassword: true });
        }
      } else {
        this.messageShow("Alamat email tidak valid");
        this.setState({ errorEmail: true });
      }
    }
  };

  pageInfo = () => {
    this.setState({ loading: true });
    const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
    const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios
      .post(base_url + "/olshop_api/page/info/", formdatau)
      .then((response) => {
        if (isMounted) {
          this.setState({ loading: false });
          if (_.isObject(response.data.otl_info)) {
            let otl_info = response.data.otl_info;
            this.setState({
              otl_info: otl_info,
            });
            ls.set(`otl_info`, otl_info);
            if (_.isString(otl_info.otl_name)) {
              document.title = otl_info.otl_name;
            }
          }
          if (_.isObject(response.data.trx_info)) {
            const trxInfoResp = response.data.trx_info;
            if (_.isString(trxInfoResp.trx_uxid)) {
              ls.set(lsShoppingCartTrxInfo, trxInfoResp);
            }
          }
          if (_.isArray(response.data.cart_list)) {
            const cartListResp = response.data.cart_list;
            ls.set(lsShoppingCartCartList, cartListResp);
          }
          if (_.isObject(response.data.usr_info)) {
            this.setState({ usr_info: response.data.usr_info });
            ls.set("usr_info", response.data.usr_info);
            this.props.history.push("/home");
          } else {
            this.setState({ usr_info: false });
            ls.set("usr_info", false);
          }
        }
      });
  };

  handleShoppingCartOpen = () => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      ls.set(lsShoppingCartTrxInfo, this.state.trx_info);
      ls.set(lsShoppingCartCartList, this.state.cart_list);
      this.props.history.push("/shoppingcart");
    }
  };

  lostOnClose = () => {
    this.setState({ lost: false });
  };

  lostOpen = () => {
    this.setState({ lost: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ paddingBottom: 56 }}>
          <div className={classes.page}>
            <FullHeight canExceed>
              <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Login
                  </Typography>
                  <form className={classes.form} noValidate>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Alamat Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                      error={this.state.errorEmail}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                      error={this.state.errorPassword}
                    />
                    <Grid item xs={12} style={{ marginTop: "15px" }}>
                      <Card>
                        <CardContent className={classes.cardContent}>
                          <Typography variant="subtitle2">
                            {this.state.errorMessage}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={this.handleSubmit}
                    >
                      LOGIN
                    </Button>
                    <Grid container>
                      <Grid item xs style={{ paddingTop: 10 }}>
                        <BlinK
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={this.lostOpen}
                          color="primary"
                        >
                          Lupa password? Reset disini
                        </BlinK>
                      </Grid>
                      <Grid item style={{ paddingTop: 10 }}>
                        <Link to="/register" variant="body2">
                          Daftar klik disini
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <Box mt={10}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {"Baca "}
                    <Link to="/ketentuan" variant="body2">
                      Ketentuan
                    </Link>{" "}
                    dan{" "}
                    <Link to="/kebijakanprivasi" variant="body2">
                      Kebijakan Privasi
                    </Link>
                  </Typography>
                </Box>
                <Box mb={10}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {"Copyright © "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                      href="/"
                    >
                      {this.state.otl_info.otl_name
                        ? this.state.otl_info.otl_name
                        : "Sistem Kasir Android"}
                    </a>{" "}
                    {new Date().getFullYear()}
                    {"."}
                  </Typography>
                </Box>
              </Container>
              <LostPassword
                classes={classes}
                open={this.state.lost}
                onClose={this.lostOnClose}
              />
            </FullHeight>
          </div>
          <div className={classes.displayMobile}>
            <WhatsAppLink
              phone={this.state.otl_info.otl_whatsapp}
              label="Butuh Bantuan ?"
              text={
                this.state.otl_info.otl_name
                  ? `Hallo ${this.state.otl_info.otl_name}`
                  : "Hallo"
              }
            />
          </div>
        </div>

        <OlsNavbarDoor
          value="login"
          text={
            this.state.otl_info.otl_name
              ? `Hallo ${this.state.otl_info.otl_name}`
              : "Hallo"
          }
          outlet={this.state.otl_info}
          onCartClick={this.handleShoppingCartOpen}
        />

        <div className={classes.displayMobile}>
          <OlsBottomNav value=" " />
        </div>

        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(OlsLogin);
