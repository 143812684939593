/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 4,
  },
  content: {
    textAlign: "center",
    padding: "8px 16px",
    "&:last-child": {
      paddingBottom: "8px",
    },
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  fullHeight: {
    height: "100%",
  },
}));

const OlsPaidRow = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Card>
        <CardContent className={classes.content}>
          <Typography className={classes.label} variant="h4">
            DIBAYAR
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default OlsPaidRow;
