/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import "../../config";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import { CardActionArea } from "@material-ui/core";
import axios from "axios";
import ls from "local-storage";
import LinearProgress from "@material-ui/core/LinearProgress";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import "typeface-roboto";

let isMounted = false;

const useStyles = (theme) => ({
  root: {
    padding: "1px 0px 5px 0px",
  },
  content: {
    padding: "4px 16px",
    "&:last-child": {
      paddingBottom: "4px",
    },
  },
  fullHeight: {
    height: "100%",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  dialogContentStyle: {
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  textCenter: {
    textAlign: "center",
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

const LogoutConfirm = (props) => {
  const { classes, onClose, onYes, open } = props;

  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleYes = () => {
    if (isMounted) {
      setLoading(true);
      axios.post(base_url + "/olshop_api/acc/acc_logout/").then(() => {
        if (isMounted) {
          setLoading(false);
          ls.set("usr_info", false);
          onYes();
        }
      });
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      fullWidth
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle
        className={classes.textCenter}
        id="simple-dialog-title"
      ></DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <div style={{ paddingTop: 25, paddingBottom: 25 }}>
          <Typography className={classes.textCenter} variant="h6">
            Yakin ingin Logout?
          </Typography>
        </div>
        <div style={{ height: 5 }}>
          <LinearProgress style={loading ? {} : { display: "none" }} />
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonActionsStyle}>
        <Button
          className={classes.buttonStyle}
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          TIDAK
        </Button>
        <Button
          className={classes.buttonStyle}
          onClick={handleYes}
          color="primary"
          variant="contained"
          autoFocus
        >
          YA
        </Button>
      </DialogActions>
    </Dialog>
  );
};

class UsrLogout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
    };
  }

  componentDidMount() {
    isMounted = true;
  }

  componentWillUnmount() {
    isMounted = false;
  }

  handleConfirmOpen = () => {
    this.setState({ confirm: true });
  };

  handleConfirmClose = () => {
    this.setState({ confirm: false });
  };

  handleConfirmYes = () => {
    this.setState({ confirm: false });
    this.props.history.push("/login");
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Card>
          <CardActionArea onClick={this.handleConfirmOpen}>
            <CardContent className={classes.content}>
              <Grid container spacing={2}>
                <Grid item>
                  <Grid
                    item
                    className={classes.fullHeight}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <PowerSettingsNewIcon />
                      </Avatar>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    className={classes.fullHeight}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography variant="h6">Logout</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
          <LogoutConfirm
            classes={classes}
            open={this.state.confirm}
            onClose={this.handleConfirmClose}
            onYes={this.handleConfirmYes}
          />
        </Card>
      </div>
    );
  }
}

export default withStyles(useStyles)(withRouter(UsrLogout));
