/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1px 4px 1px 40px",
  },
  content: {
    padding: "4px 16px",
    "&:last-child": {
      paddingBottom: "4px",
    },
  },
  label: {
    color: theme.palette.primary.main,
  },
  fullHeight: {
    height: "100%",
  },
}));

const OlsShippingRow = (props) => {
  const classes = useStyles();
  const { label, value } = props;

  return (
    <div className={classes.root}>
      <Card>
        <CardContent className={classes.content}>
          <Grid container spacing={2} justify="space-between">
            <Grid item>
              <Grid
                item
                className={classes.fullHeight}
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography variant="subtitle1">{label}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                item
                className={classes.fullHeight}
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography variant="h6">
                    <NumberFormat
                      value={value}
                      isNumericString
                      displayType="text"
                      decimalScale={0}
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default OlsShippingRow;
