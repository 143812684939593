/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 4,
  },
  label: {
    color: theme.palette.primary.main,
  },
  valueWrapper: {
    height: "100%",
  },
}));

const OlsLabelValueRow = (props) => {
  const classes = useStyles();
  const { label, value, onClick } = props;

  return (
    <div className={classes.root}>
      <Card onClick={() => onClick()}>
        <CardActionArea>
          <CardContent>
            <Grid container spacing={2} justify="space-between">
              <Grid item className={classes.labelGrid}>
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography className={classes.label} variant="h5">
                      {label}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  item
                  container
                  className={classes.valueWrapper}
                  direction="column"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography variant="h5">
                      <NumberFormat
                        value={value}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default OlsLabelValueRow;
