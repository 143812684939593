/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import Avatar from "@material-ui/core/Avatar";
import AccountIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1px 0px 5px 0px",
  },
  content: {
    padding: "4px 16px",
    "&:last-child": {
      paddingBottom: "4px",
    },
  },
  label: {
    color: theme.palette.primary.main,
  },
  fullHeight: {
    height: "100%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
  },
}));

const UsrRealname = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <div className={classes.root}>
      <Card>
        <CardContent className={classes.content}>
          <Grid container spacing={2}>
            <Grid item>
              <Grid
                item
                className={classes.fullHeight}
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Avatar className={classes.avatar}>
                    <AccountIcon />
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                item
                className={classes.fullHeight}
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography variant="h5">{data.usr_realname}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption">{data.usr_email}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default UsrRealname;
