/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import "../../config";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import VisibilitySensor from "react-visibility-sensor";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import OlsProductGrid from "../../component/OlsProductGrid/OlsProductGrid";
import OlsSearchSort from "../../container/OlsSearch/OlsSearchSort";
import OlsBannerImageSlider from "../../container/OlsBannerImageSlider/OlsBannerImageSlider";
import WhatsAppLink from "../../component/WhatsAppLink/WhatsAppLink";
import OlsCartEdit from "../../container/OlsCartEdit/OlsCartEdit";
import OlsProductView from "../../container/OlsProductView/OlsProductView";
import Divider from "@material-ui/core/Divider";
import SortByIcon from "../../component/SortByIcon/SortByIcon";
import Grid from "@material-ui/core/Grid";
import SelectAllOutlinedIcon from "@material-ui/icons/SelectAllOutlined";
import OlsCategorySelect from "../../container/OlsCategorySelect/OlsCategorySelect";
import OlsFooter from "../../component/OlsFooter/OlsFooter";
import OlsNavbar from "../../component/OlsNavbar/OlsNavbar";
import FacebookLink from "../../component/FacebookLink/FacebookLink";

let isMounted = false;

const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  postList: {
    padding: 4,
    display: "block",
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
  },
  content: {
    padding: 4,
    display: "block",
    minHeight: 1000,
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usr_info: ls.get("usr_info") || false,
      otl_info: ls.get(`otl_info`) || {},
      banner_list: ls.get(`otl_banner_list`) || [],
      trx_info: ls.get(`otl_ShoppingCart_TrxInfo`) || {},
      cart_list: [],
      loading: false,
      more: false,
      category_list: ls.get(`otl_category_list`) || [],
      product_list: ls.get(`otl_product_list`) || [],
      search_text: "",
      cartEdit: false,
      cartEditData: {},
      cartView: false,
      cartViewData: {},
    };
    this.isProductBest = ls.get("isProductBest") || false;
    this.categorySelected = "";
  }

  componentDidMount() {
    isMounted = true;

    let otl_info = ls.get(`otl_info`) || {};
    this.setState({
      otl_info: otl_info,
    });

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });
    if (usr_info !== false) {
      if (_.isString(usr_info.usr_uxid)) {
        const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
        const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
        const trxInfo = ls.get(lsShoppingCartTrxInfo);
        if (_.isObject(trxInfo)) {
          if (_.isString(trxInfo.trx_client_uxid)) {
            if (trxInfo.trx_client_uxid === usr_info.usr_uxid) {
              this.setState({ trx_info: trxInfo });
              const cartList = ls.get(lsShoppingCartCartList);
              if (_.isArray(cartList)) {
                this.setState({ cart_list: cartList });
              }
            }
          }
        }
      }
    }

    if (_.isString(otl_info.otl_name)) {
      document.title = otl_info.otl_name;
    }
    this.pageInfo();
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    isMounted = false;
  }

  pageInfo = () => {
    this.setState({ loading: true });
    const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
    const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios
      .post(base_url + "/olshop_api/page/info/", formdatau)
      .then((response) => {
        if (isMounted) {
          this.setState({ loading: false });
          if (_.isObject(response.data.usr_info)) {
            this.setState({ usr_info: response.data.usr_info });
            ls.set("usr_info", response.data.usr_info);
          }
          if (_.isObject(response.data.otl_info)) {
            let otl_info = response.data.otl_info;
            this.setState({
              otl_info: otl_info,
            });
            ls.set(`otl_info`, otl_info);
            if (_.isString(otl_info.otl_name)) {
              document.title = otl_info.otl_name;
            }
          }
          if (_.isArray(response.data.banner_list)) {
            let otlBanners = response.data.banner_list;
            if (_.size(otlBanners) > 0) {
              this.setState({
                banner_list: otlBanners,
              });
            } else {
              otlBanners.push(base_url + "/images/banner/no_banner1.png");
              otlBanners.push(base_url + "/images/banner/no_banner2.png");
              otlBanners.push(base_url + "/images/banner/no_banner3.png");
              otlBanners.push(base_url + "/images/banner/no_banner4.png");
              this.setState({
                banner_list: otlBanners,
              });
            }
            ls.set(`otl_banner_list`, otlBanners);
          }
          if (_.isArray(response.data.cart_list)) {
            const cartListResp = response.data.cart_list;
            this.setState({ cart_list: cartListResp });
            ls.set(lsShoppingCartCartList, cartListResp);
          }
          if (_.isObject(response.data.trx_info)) {
            const trxInfoResp = response.data.trx_info;
            if (_.isString(trxInfoResp.trx_uxid)) {
              if (trxInfoResp.trx_status !== "TEMP") {
                ls.set(lsShoppingCartTrxInfo, {});
                ls.set(lsShoppingCartCartList, []);
                this.setState({ trx_info: {} });
                this.setState({ cart_list: [] });
              } else {
                this.setState({ trx_info: trxInfoResp });
                ls.set(lsShoppingCartTrxInfo, trxInfoResp);
              }
            }
          }
          this.pageLoad();
          this.doListLoad();
        }
      });
  };

  pageLoad = () => {
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios
      .post(base_url + "/olshop_api/page/load/", formdatau)
      .then((response) => {});
  };

  handleCategorySelect = (value) => {
    if (isMounted) {
      this.categorySelected = value;
      this.doListLoad();
    }
  };

  handleSearchOnChange = (value) => {
    if (isMounted) {
      if (value.length) {
        this.setState({
          search_text: value,
        });
        this.setState({ loading: true });
        const formdataps = new FormData();
        formdataps.append("category_name", this.categorySelected);
        formdataps.append("search", value);
        formdataps.append("last_count", 0);
        axios
          .post(base_url + "/olshop_api/product/product_search/", formdataps)
          .then((response) => {
            if (isMounted) {
              this.setState({ loading: false });
              if (_.isArray(response.data.product_list)) {
                this.setState({
                  product_list: response.data.product_list,
                });
              }
            }
          });
      } else {
        this.doListLoad();
      }
    }
  };

  handleSearchOnSubmit = (value) => {
    if (isMounted) {
      if (value.length) {
        this.setState({
          search_text: value,
        });
        this.setState({ loading: true });
        const formdataps = new FormData();
        formdataps.append("category_name", this.categorySelected);
        formdataps.append("search", value);
        formdataps.append("last_count", 0);
        axios
          .post(base_url + "/olshop_api/product/product_search/", formdataps)
          .then((response) => {
            if (isMounted) {
              this.setState({ loading: false });
              if (_.isArray(response.data.product_list)) {
                this.setState({
                  product_list: response.data.product_list,
                });
              }
            }
          });
        window.scrollTo(0, 0);
      } else {
        this.doListLoad();
      }
    }
  };

  handleShoppingCartOpen = () => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      ls.set(lsShoppingCartTrxInfo, this.state.trx_info);
      ls.set(lsShoppingCartCartList, this.state.cart_list);
      this.props.history.push("/shoppingcart");
    }
  };

  doListLoad = () => {
    if (isMounted) {
      const lastCount = 0;
      this.setState({ loading: true });
      const formdatapl = new FormData();
      formdatapl.append("category_name", this.categorySelected);
      formdatapl.append("last_count", lastCount);
      if (this.isProductBest) {
        axios
          .post(base_url + "/olshop_api/product/product_best_list/", formdatapl)
          .then((response) => {
            if (isMounted) {
              this.setState({ loading: false });
              if (_.isArray(response.data.product_list)) {
                if (_.size(response.data.product_list) > 0) {
                  this.setState({
                    more: true,
                  });
                } else {
                  this.setState({
                    more: false,
                  });
                }
                this.setState({
                  product_list: response.data.product_list,
                });
                ls.set(`otl_product_list`, response.data.product_list);
              }
            }
          });
      } else {
        axios
          .post(base_url + "/olshop_api/product/product_list/", formdatapl)
          .then((response) => {
            if (isMounted) {
              this.setState({ loading: false });
              if (_.isArray(response.data.product_list)) {
                if (_.size(response.data.product_list) > 0) {
                  this.setState({
                    more: true,
                  });
                } else {
                  this.setState({
                    more: false,
                  });
                }
                this.setState({
                  product_list: response.data.product_list,
                });
                ls.set(`otl_product_list`, response.data.product_list);
              }
            }
          });
      }
      const formdatac = new FormData();
      formdatac.append("last_count", 0);
      axios
        .post(
          base_url + "/olshop_api/product/product_category_list/",
          formdatac
        )
        .then((response) => {
          if (isMounted) {
            if (_.isArray(response.data.category_list)) {
              this.setState({
                category_list: response.data.category_list,
              });
              ls.set(`otl_category_list`, response.data.category_list);
            }
          }
        });
      window.scrollTo(0, 0);
    }
  };

  doListMore = () => {
    if (isMounted) {
      const lastCount = _.size(this.state.product_list);
      const formdatapl = new FormData();
      formdatapl.append("category_name", this.categorySelected);
      formdatapl.append("last_count", lastCount);
      if (this.isProductBest) {
        axios
          .post(base_url + "/olshop_api/product/product_best_list/", formdatapl)
          .then((response) => {
            if (isMounted) {
              if (_.isArray(response.data.product_list)) {
                if (_.size(response.data.product_list) > 0) {
                  this.setState({
                    more: true,
                  });
                  const productList = _.unionBy(
                    this.state.product_list,
                    response.data.product_list,
                    "product_uxid"
                  );
                  this.setState({
                    product_list: productList,
                  });
                } else {
                  this.setState({
                    more: false,
                  });
                }
              }
            }
          });
      } else {
        axios
          .post(base_url + "/olshop_api/product/product_list/", formdatapl)
          .then((response) => {
            if (isMounted) {
              if (_.isArray(response.data.product_list)) {
                if (_.size(response.data.product_list) > 0) {
                  this.setState({
                    more: true,
                  });
                  const productList = _.unionBy(
                    this.state.product_list,
                    response.data.product_list,
                    "product_uxid"
                  );
                  this.setState({
                    product_list: productList,
                  });
                } else {
                  this.setState({
                    more: false,
                  });
                }
              }
            }
          });
      }
    }
  };

  doSearchMore = () => {
    if (isMounted) {
      const lastCount = _.size(this.state.product_list);
      const formdataps = new FormData();
      formdataps.append("category_name", this.categorySelected);
      formdataps.append("search", this.state.search_text);
      formdataps.append("last_count", lastCount);
      axios
        .post(base_url + "/olshop_api/product/product_search/", formdataps)
        .then((response) => {
          if (isMounted) {
            if (_.isArray(response.data.product_list)) {
              if (_.size(response.data.product_list) > 0) {
                this.setState({
                  more: true,
                });
                const productList = _.unionBy(
                  this.state.product_list,
                  response.data.product_list,
                  "product_uxid"
                );
                this.setState({
                  product_list: productList,
                });
              } else {
                this.setState({
                  more: false,
                });
              }
            }
          }
        });
    }
  };

  handleLoadMore = (isMore) => {
    if (isMore) {
      this.setState({
        more: true,
      });
      if (_.isEmpty(this.state.search_text)) {
        this.doListMore();
      } else {
        this.doSearchMore();
      }
    }
  };

  handleCartEditClose = () => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
    }
  };

  handleCartEditDelete = (fields) => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
      const usrInfo = ls.get("usr_info");
      if (_.isString(usrInfo.usr_realname)) {
        if (_.isString(this.state.trx_info.trx_uxid)) {
          // untuk memastikan object trx terload dengan benar
          // untuk memastikan object product terload dengan benar
          if (this.state.trx_info.trx_status === "TEMP") {
            const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
            const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
            this.setState({ loading: true });
            const formdata = new FormData();
            formdata.append("cart_uxid", fields.cart_uxid);
            formdata.append("cart_qty", fields.cart_qty);
            formdata.append("cart_note", fields.cart_note);
            axios
              .post(base_url + "/olshop_api/trx/cart_delete/", formdata)
              .then((response) => {
                if (isMounted) {
                  this.setState({ loading: false });
                  if (_.isObject(response.data.trx_info)) {
                    const trxInfoResp = response.data.trx_info;
                    if (_.isString(trxInfoResp.trx_uxid)) {
                      this.setState({ trx_info: trxInfoResp });
                      ls.set(lsShoppingCartTrxInfo, trxInfoResp);
                    }
                  }
                  if (_.isArray(response.data.cart_list)) {
                    const cartListResp = response.data.cart_list;
                    this.setState({ cart_list: cartListResp });
                    ls.set(lsShoppingCartCartList, cartListResp);
                  }
                  //= toast start
                  if (_.isString(response.data.status)) {
                    if (response.data.status === "OK") {
                      if (_.isString(response.data.message)) {
                        const { message } = response.data;
                        this.toastShow("success", message);
                      }
                    } else if (_.isString(response.data.message)) {
                      const { message } = response.data;
                      this.toastShow("error", message);
                    }
                  }
                  //= toast end
                }
              });
          } else {
            this.toastShow("error", "Tidak dapat hapus belanja");
          }
        }
      } else {
        this.props.history.push("/login");
      }
    }
  };

  handleCartEditSave = (fields) => {
    if (isMounted) {
      this.setState({
        cartEdit: false,
      });
      const usrInfo = ls.get("usr_info");
      if (_.isString(usrInfo.usr_realname)) {
        if (_.isString(this.state.trx_info.trx_uxid)) {
          // untuk memastikan object trx terload dengan benar
          // untuk memastikan object product terload dengan benar
          if (this.state.trx_info.trx_status === "TEMP") {
            const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
            const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
            this.setState({ loading: true });
            const formdata = new FormData();
            formdata.append("cart_uxid", fields.cart_uxid);
            formdata.append("cart_qty", fields.cart_qty);
            formdata.append("cart_note", fields.cart_note);
            axios
              .post(base_url + "/olshop_api/trx/cart_update/", formdata)
              .then((response) => {
                if (isMounted) {
                  this.setState({ loading: false });
                  if (_.isObject(response.data.trx_info)) {
                    const trxInfoResp = response.data.trx_info;
                    if (_.isString(trxInfoResp.trx_uxid)) {
                      this.setState({ trx_info: trxInfoResp });
                      ls.set(lsShoppingCartTrxInfo, trxInfoResp);
                    }
                  }
                  if (_.isArray(response.data.cart_list)) {
                    const cartListResp = response.data.cart_list;
                    this.setState({ cart_list: cartListResp });
                    ls.set(lsShoppingCartCartList, cartListResp);
                  }
                  //= toast start
                  if (_.isString(response.data.status)) {
                    if (response.data.status === "OK") {
                      if (_.isString(response.data.message)) {
                        const { message } = response.data;
                        this.toastShow("success", message);
                      }
                    } else if (_.isString(response.data.message)) {
                      const { message } = response.data;
                      this.toastShow("error", message);
                    }
                  }
                  //= toast end
                }
              });
          } else {
            this.toastShow("error", "Tidak dapat update belanja");
          }
        }
      } else {
        this.props.history.push("/login");
      }
    }
  };

  handleCartViewOpen = (productOpen) => {
    if (isMounted) {
      this.setState({
        cartViewData: productOpen,
        cartView: true,
      });
    }
  };

  handleCartViewClose = () => {
    if (isMounted) {
      this.setState({
        cartView: false,
      });
    }
  };

  handleCartViewBuy = (productBuy) => {
    if (isMounted) {
      this.setState({
        cartView: false,
      });
      const usrInfo = ls.get("usr_info");
      if (_.isString(usrInfo.usr_realname)) {
        if (_.isString(this.state.trx_info.trx_uxid)) {
          // untuk memastikan object trx terload dengan benar
          if (_.isString(productBuy.product_uxid)) {
            // untuk memastikan object product terload dengan benar
            if (this.state.trx_info.trx_status === "TEMP") {
              const cart = _.find(this.state.cart_list, {
                cart_product_uxid: productBuy.product_uxid,
              });
              if (_.isObject(cart)) {
                this.setState({
                  cartEdit: true,
                  cartEditData: cart,
                });
              } else {
                const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
                const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
                this.setState({ loading: true });
                const formdata = new FormData();
                formdata.append("cart_trx_uxid", this.state.trx_info.trx_uxid);
                formdata.append("cart_product_uxid", productBuy.product_uxid);
                formdata.append("cart_qty", 1);
                formdata.append("cart_note", "");
                axios
                  .post(base_url + "/olshop_api/trx/cart_add/", formdata)
                  .then((response) => {
                    if (isMounted) {
                      this.setState({ loading: false });
                      if (_.isObject(response.data.trx_info)) {
                        const trxInfoResp = response.data.trx_info;
                        if (_.isString(trxInfoResp.trx_uxid)) {
                          this.setState({ trx_info: trxInfoResp });
                          ls.set(lsShoppingCartTrxInfo, trxInfoResp);
                        }
                      }
                      if (_.isArray(response.data.cart_list)) {
                        const cartListResp = response.data.cart_list;
                        this.setState({ cart_list: cartListResp });
                        ls.set(lsShoppingCartCartList, cartListResp);
                      }
                      //= toast start
                      if (_.isString(response.data.status)) {
                        if (response.data.status === "OK") {
                          if (_.isString(response.data.message)) {
                            const { message } = response.data;
                            this.toastShow("success", message);
                          }
                        } else if (_.isString(response.data.message)) {
                          const { message } = response.data;
                          this.toastShow("error", message);
                        }
                      }
                      //= toast end
                      const cartEd = _.find(this.state.cart_list, {
                        cart_product_uxid: productBuy.product_uxid,
                      });
                      if (_.isObject(cartEd)) {
                        this.setState({
                          cartEdit: true,
                          cartEditData: cartEd,
                        });
                      }
                    }
                  });
              }
            } else {
              this.toastShow("error", "Tidak dapat beli produk ini");
            }
          }
        }
      } else {
        this.props.history.push("/login");
      }
    }
  };

  handleSearchIconOnSort = (value) => {
    if (isMounted) {
      this.isProductBest = value;
      ls.set("isProductBest", value);
      this.doListLoad();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingBottom: 56 }}>
        <OlsBannerImageSlider data={this.state.banner_list} />
        <div className={classes.page}>
          <div className={classes.displayMobile}>
            <OlsCategorySelect
              data={this.state.category_list}
              onSelect={this.handleCategorySelect}
            />
            <OlsSearchSort
              value={this.isProductBest}
              onChange={this.handleSearchOnChange}
              onSubmit={this.handleSearchOnSubmit}
              onSort={this.handleSearchIconOnSort}
            />
          </div>

          <div className={classes.displayDesktop}>
            <div style={{ paddingTop: 15, paddingBottom: 5 }}>
              <Grid container justify="space-between">
                <Grid item>
                  <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <div style={{ paddingTop: 5 }}>
                        <Grid container item>
                          <Grid item>
                            <SelectAllOutlinedIcon style={{ fontSize: 36 }} />
                          </Grid>
                          <Grid item>
                            <Typography
                              style={{ fontSize: 14, marginLeft: 5 }}
                              variant="overline"
                              gutterBottom
                              noWrap
                            >
                              Daftar Produk
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid item container direction="column" justify="center">
                    <Grid item>
                      <div style={{ paddingTop: 5 }}>
                        <OlsCategorySelect
                          type="label"
                          data={this.state.category_list}
                          onSelect={this.handleCategorySelect}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <SortByIcon
                        value={this.isProductBest}
                        onSort={this.handleSearchIconOnSort}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className={classes.content}>
            {this.state.product_list.map((product) => (
              <OlsProductGrid
                key={product.product_uxid}
                data={product}
                onSelect={(data) => this.handleCartViewOpen(data)}
              />
            ))}
          </div>
        </div>

        <VisibilitySensor onChange={this.handleLoadMore}>
          <div>
            <div style={{ height: 5 }}>
              <LinearProgress
                style={this.state.more ? {} : { display: "none" }}
              />
            </div>
          </div>
        </VisibilitySensor>

        <Divider />

        <OlsFooter outlet={this.state.otl_info} />

        <div className={classes.displayMobile}>
          <WhatsAppLink
            phone={this.state.otl_info.otl_whatsapp}
            label="Butuh Bantuan ?"
            text={
              this.state.otl_info.otl_name
                ? `Hallo ${this.state.otl_info.otl_name}`
                : "Hallo"
            }
          />
        </div>

        <FacebookLink
          url={
            this.state.otl_info.otl_link_fb
              ? this.state.otl_info.otl_link_fb
              : ""
          }
        />

        <OlsProductView
          data={this.state.cartViewData}
          onClose={this.handleCartViewClose}
          onBuy={this.handleCartViewBuy}
          open={this.state.cartView}
        />
        <OlsCartEdit
          data={this.state.cartEditData}
          onClose={this.handleCartEditClose}
          onSave={this.handleCartEditSave}
          onDelete={this.handleCartEditDelete}
          open={this.state.cartEdit}
        />

        <OlsNavbar
          value="home"
          title={
            this.state.otl_info.otl_name ? this.state.otl_info.otl_name : "Home"
          }
          text={
            this.state.otl_info.otl_name
              ? `Hallo ${this.state.otl_info.otl_name}`
              : "Hallo"
          }
          outlet={this.state.otl_info}
          cartCount={_.size(this.state.cart_list)}
          onCartClick={this.handleShoppingCartOpen}
          onSearchChange={this.handleSearchOnChange}
          onSearchSubmit={this.handleSearchOnSubmit}
        />

        <div className={classes.displayMobile}>
          <OlsBottomNav value="home" />
        </div>

        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Home);
