/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 4,
  },
  image: {
    width: 80,
    height: 80,
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    minWidth: 80,
    minHeight: 80,
    borderRadius: 10,
  },
  detail: {
    flex: 1,
  },
  title: {
    color: theme.palette.primary.main,
  },
  infoWrapper: {
    height: "100%",
  },
  priceWrapper: {
    height: "100%",
  },
  price: {
    marginTop: 8,
  },
  discount: {
    position: "absolute",
    top: 2,
    right: 2,
    backgroundColor: "red",
    padding: 5,
    color: "white",
    borderRadius: 20,
    minWidth: 50,
    textAlign: "center",
    boxShadow: "1px 3px 1px #9E9E9E",
    fontWeight: "bold",
  },
  note: {
    backgroundColor: theme.palette.primary.light,
    padding: "2px 5px",
    color: "white",
    borderRadius: 8,
    fontWeight: "bold",
  },
}));

const NumberQty = (props) => {
  const { qty } = props;
  const [value, setValue] = React.useState("0");

  const doUpdate = () => {
    const valStr = `${qty}`;
    const arrVal = valStr.split(".");
    if (arrVal.length > 1) {
      const decimalStr = arrVal[1];
      const decimalDbl = Number(decimalStr.replace(/[^0-9.-]+/g, ""));
      if (decimalDbl > 0) {
        setValue(arrVal[0] + "," + arrVal[1]);
      } else {
        const valStrZ = arrVal[0];
        setValue(valStrZ);
      }
    } else {
      setValue(valStr);
    }
  };

  useEffect(doUpdate, [qty]);

  return <>{value}</>;
};

const OlsCartRow = (props) => {
  const classes = useStyles();
  const { data, onOpen } = props;

  return (
    <div className={classes.root}>
      <Card onClick={() => onOpen(data)}>
        <CardActionArea>
          <CardContent>
            <Grid container spacing={2} justify="space-between">
              <Grid item className={classes.detail}>
                <Grid
                  item
                  container
                  className={classes.infoWrapper}
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography className={classes.title} variant="h6">
                      {data.cart_product_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {data.cart_note && (
                      <div className={classes.note}>
                        <Typography variant="body2">
                          {data.cart_note}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      <NumberQty qty={data.cart_qty} /> x{" "}
                      <NumberFormat
                        value={data.cart_product_final_price}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  item
                  container
                  className={classes.priceWrapper}
                  direction="column"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography variant="h6" className={classes.price}>
                      <NumberFormat
                        value={data.cart_sale_total}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                    {data.cart_discount_price_total > 0 && (
                      <div className={classes.discount}>
                        <Typography variant="body2">
                          <NumberFormat
                            value={data.cart_discount_price_total}
                            isNumericString
                            displayType="text"
                            decimalScale={0}
                            thousandSeparator="."
                            decimalSeparator=","
                          />
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

OlsCartRow.defaultProps = {
  data: {
    cart_uxid: "",
    cart_trx_uxid: "",
    cart_parent_uxid: "",
    cart_type: "",
    cart_flow: "",
    cart_group: "",
    cart_worker_type: "",
    cart_worker_uxid: "",
    cart_worker_realname: "",
    cart_client_uxid: "",
    cart_client_realname: "",
    cart_product_uxid: "",
    cart_product_divisi: "",
    cart_product_departemen: "",
    cart_product_sdepartemen: "",
    cart_product_category: "",
    cart_product_type: "",
    cart_product_code: "",
    cart_product_name: "",
    cart_product_unit: "",
    cart_product_weight: 0,
    cart_product_base_price: 0,
    cart_product_sale_price: 0,
    cart_product_grosir_price: 0,
    cart_product_grosir_qty: 0,
    cart_product_tbm_price: 0,
    cart_product_tbm_value: 0,
    cart_product_tbm_qty: 0,
    cart_product_tbm_status: "",
    cart_product_final_price: 0,
    cart_product_discount_type: "",
    cart_product_discount_value: 0,
    cart_product_konsinyasi: "",
    cart_pack_item_qty: 0,
    cart_qty: 0,
    cart_weight: 0,
    cart_discount_type: "",
    cart_discount_value: 0,
    cart_discount_price_unit: 0,
    cart_discount_price_total: 0,
    cart_base_total: 0,
    cart_sale_total: 0,
    cart_profit: 0,
    cart_lat: "",
    cart_lng: "",
    cart_note: "",
    cart_detail: "",
    cart_ts: 0,
    cart_hour: "",
    cart_day: "",
    cart_month: "",
    cart_year: "",
    cart_step: "",
    cart_payment_status: "",
    cart_status: "",
  },
};

export default OlsCartRow;
