/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import SortIcon from '@material-ui/icons/Sort';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    marginTop: '6px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '1rem',
  },
  iconButton: {
    padding: 10,
  },
}));

export default function OlsSearchSort(props) {
  const classes = useStyles();
  const {value, onChange, onSubmit, onSort} = props;
  const [search, setSearch] = React.useState('');
  const [best, setBest] = React.useState(false);

  const handleOnChange = (event) => {
    setSearch(event.target.value);
    onChange(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      event.preventDefault();
      setSearch(event.target.value);
      onSubmit(event.target.value);
    }
  };

  const handleClear = () => {
    setSearch('');
  };

  const handleSort = () => {
    if(best){
      setBest(false);
      onSort(false);
    } else {
      setBest(true);
      onSort(true);
    }
  };

  useEffect(() => {
    if (value) {
      setBest(true);
    } else {
      setBest(false);
    }
  }, [value]);

  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} onClick={handleClear}>
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Cari..."
        value={search}
        onChange={handleOnChange}
        onKeyPress={handleKeyPress}
        inputProps={{ style: { returnKeyType: 'search' } }}
      />
      {best === false && (
      <IconButton className={classes.iconButton} onClick={handleSort}>
        <SortByAlphaIcon />
      </IconButton>
      )}
      {best === true && (
      <IconButton className={classes.iconButton} onClick={handleSort}>
        <SortIcon />
      </IconButton>
      )}
    </Paper>
  );
}
