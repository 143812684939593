import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "typeface-roboto";

const useStyles = makeStyles((theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  footerStyle: {
    marginTop: 5,
    marginBottom: -56,
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: theme.palette.color.footer,
  },
  footerBottom: {
    height: 56,
    backgroundColor: theme.palette.color.footer,
  },
}));

const OlsFooter = (props) => {
  const classes = useStyles();
  const { outlet } = props;
  const [otl_info, setOtlInfo] = React.useState({});

  useEffect(() => {
    if (outlet) {
      setOtlInfo(outlet);
    }
  }, [outlet]);

  return (
    <div className={classes.footerStyle}>
      <div style={{ textAlign: "center" }}>
        <Typography variant="h6" noWrap>
          {otl_info.otl_name}
        </Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" noWrap>
          {otl_info.otl_address1}
        </Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="subtitle2" noWrap>
          {otl_info.otl_address2}
        </Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="subtitle2" noWrap>
          {otl_info.otl_address3}
        </Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="subtitle2" noWrap>
          {otl_info.otl_address4}
        </Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        <Typography variant="subtitle2" noWrap>
          {otl_info.otl_address5}
        </Typography>
      </div>
      <div className={classes.displayMobile}>
        <div className={classes.footerBottom}></div>
      </div>
    </div>
  );
};

export default OlsFooter;
