/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import "../../config";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Container } from "@material-ui/core";
import "typeface-roboto";
import NumberFormat from "react-number-format";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import OlsProductImageSlider from "../OlsProductImageSlider/OlsProductImageSlider";
import { useMediaQuery } from "react-responsive";
import IconWhatsApp from "@material-ui/icons/WhatsApp";
import Chip from "@material-ui/core/Chip";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dialogContentStyle: {
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  discount: {
    color: "red",
    fontWeight: "bold",
  },
}));

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

const OlsProductView = (props) => {
  const classes = useStyles();
  const [albums, setAlbums] = React.useState([]);
  const [linkWhatsApp, setLinkWhatsApp] = React.useState("");
  const isMobileScreen = useMediaQuery({ query: "(max-width: 480px)" });

  const { onClose, onBuy, data, open } = props;

  const viewInit = () => {
    if (open) {
      let otl_info = ls.get(`otl_info`) || {};
      if (_.isString(otl_info.otl_whatsapp)) {
        let phone = otl_info.otl_whatsapp;
        if (phone.length > 7) {
          let phoneNew = phone;
          const negara = phone.substring(0, 1);
          if (negara === "0") {
            const phoneNum = phone.substring(1, phone.length);
            phoneNew = `62${phoneNum}`;
          }
          let text =
            "Tanya produk : " +
            base_url +
            "/product/" +
            data.product_uxid +
            "/";
          const textWhatsApp = encodeURI(text);
          let linkWhatsAppTx = `https://api.whatsapp.com/send?phone=${phoneNew}&text=${textWhatsApp}`;
          setLinkWhatsApp(linkWhatsAppTx);
        }
      }
      if (_.isString(data.product_image_url)) {
        const productImageUrlFilename = data.product_image_url;
        setAlbums([productImageUrlFilename]);
      }
      if (_.isString(data.product_uxid)) {
        const formdatai = new FormData();
        formdatai.append("product_uxid", data.product_uxid);
        axios
          .post(base_url + "/olshop_api/product/product_photo_list/", formdatai)
          .then((response) => {
            if (_.isArray(response.data.photo_list)) {
              setAlbums(response.data.photo_list);
            }
          });
      }
    }
  };

  useEffect(viewInit, [open, data]);

  const handleClose = () => {
    onClose();
  };

  const handleBuy = () => {
    onBuy(data);
  };

  return (
    <Dialog
      maxWidth={isMobileScreen ? "xs" : "md"}
      fullWidth
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.titleStyle} id="simple-dialog-title">
        <Link
          style={{ color: "inherit", textDecoration: "inherit" }}
          to={`/product/${data.product_uxid}`}
        >
          {data.product_name}
        </Link>
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <OlsProductImageSlider album={albums} />
        <Container>
          <Typography className={classes.textCenter} variant="h6">
            Harga :{" "}
            <NumberFormat
              value={data.product_sale_price}
              isNumericString
              displayType="text"
              decimalScale={0}
              thousandSeparator="."
              decimalSeparator=","
            />{" "}
            / {data.product_unit}
          </Typography>

          {data.product_discount_value > 0 && <Divider />}
          {data.product_discount_value > 0 && (
            <Typography className={classes.textCenter} variant="subtitle2">
              Diskon :{" "}
              <NumberFormat
                className={classes.discount}
                value={data.product_discount_value}
                isNumericString
                displayType="text"
                decimalScale={0}
                thousandSeparator="."
                decimalSeparator=","
              />
              {data.product_discount_type === "PERCENTAGE" ? " %" : ""}
            </Typography>
          )}

          <Divider />

          {data.product_detail > 0 && <Divider />}
          <Typography className={classes.textCenter} variant="body1">
            {data.product_detail}
          </Typography>
        </Container>
      </DialogContent>
      <DialogActions className={classes.buttonActionsStyle}>
        <Button
          className={classes.buttonStyle}
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          TUTUP
        </Button>
        <a
          style={
            linkWhatsApp.length
              ? { textDecoration: "none", marginLeft: -10 }
              : { display: "none" }
          }
          target="_blank"
          rel="noopener noreferrer"
          href={linkWhatsApp}
        >
          <Chip
            icon={<IconWhatsApp />}
            label="WhatsApp"
            clickable
            color="primary"
          />
        </a>
        <Button
          className={classes.buttonStyle}
          onClick={handleBuy}
          color="primary"
          variant="contained"
        >
          BELI
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OlsProductView;
