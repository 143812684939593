/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconWhatsApp from '@material-ui/icons/WhatsApp';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  Chip: {
    borderRadius: 4,
    height:40,
    padding:6,
    fontWeight:'bold',
  },
  whatsapp: {
    position: 'fixed',
    left: 'auto',
    right: 'auto',
    bottom: theme.spacing(10),
  },
}));

const WhatsAppLink = (props) => {
  const classes = useStyles();
  const { label, phone, text } = props;
  const [link, setLink] = React.useState('');

  useEffect(() => {
    if (_.isString(phone)) {
      if (phone.length > 7) {
        let phoneNew = phone;
        const negara = phone.substring(0, 1);
        if (negara === '0') {
          const phoneNum = phone.substring(1, phone.length);
          phoneNew = `62${phoneNum}`;
        }
        if (_.isString(text)) {
          if (text.length > 0) {
            const textWhatsApp = encodeURI(text);
            const linkWhatsApp = `https://api.whatsapp.com/send?phone=${phoneNew}&text=${textWhatsApp}`;
            setLink(linkWhatsApp);
          } else {
            const linkWhatsApp = `https://api.whatsapp.com/send?phone=${phoneNew}`;
            setLink(linkWhatsApp);
          }
        } else {
          const linkWhatsApp = `https://api.whatsapp.com/send?phone=${phoneNew}`;
          setLink(linkWhatsApp);
        }
      }
    }
  }, [phone, text]);

  return (
    <div className={classes.whatsapp}>
      <a style={link.length ? { textDecoration: 'none', paddingLeft: 30 } : { display: 'none' }} target="_blank" rel="noopener noreferrer" href={link}>
        <Chip className={classes.Chip}
          icon={<IconWhatsApp />}
          label={label}
          clickable
          color="primary"
        />
      </a>
    </div>
  );
};

export default WhatsAppLink;
