/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import { Divider } from "@material-ui/core";
import "typeface-roboto";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 4,
  },
  cardWrapper: {
    marginBottom: 5,
  },
  dialogContentStyle: {
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonSecondary: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const TrxAlamat = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <div className={classes.root}>
      <Card className={classes.cardWrapper}>
        <CardContent style={{ minHeight: 100 }}>
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_realname}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr1}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr2}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr3}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr4}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr5}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_phone}
          </Typography>
          <Divider />
        </CardContent>
      </Card>
    </div>
  );
};

export default TrxAlamat;
