/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import "../../config";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FullHeight from "react-full-height";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";
import "typeface-roboto";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import OlsNavbarDoor from "../../component/OlsNavbar/OlsNavbarDoor";
import WhatsAppLink from "../../component/WhatsAppLink/WhatsAppLink";
import { Parser } from "html-to-react";

let isMounted = false;

const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    paddingTop: 62,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    padding: 15,
    margin: theme.spacing(3, 0, 2),
  },
  dialogContentStyle: {
    minHeight: 120,
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonSecondary: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  cardContent: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    padding: "4px 16px",
    "&:last-child": {
      paddingBottom: "4px",
    },
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

class Kebijakanprivasi extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      usr_info: ls.get("usr_info") || false,
      otl_info: ls.get(`otl_info`) || {},
      content: "",
      loading: false,
    };
  }

  componentDidMount() {
    isMounted = true;
    let otl_info = ls.get(`otl_info`) || {};
    this.setState({
      otl_info: otl_info,
    });
    if (_.isString(otl_info.otl_name)) {
      document.title = otl_info.otl_name;
    }

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });
    this.getContent();
  }

  componentDidUpdate() {
    // console.log(this.state);
  }

  componentWillUnmount() {
    isMounted = false;
  }

  getContent = () => {
    this.setState({ loading: true });
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios.post(base_url + "/kebijakanprivasi/", formdatau).then((response) => {
      if (isMounted) {
        this.setState({ loading: false });
        this.setState({ content: response.data });
      }
    });
  };

  handleShoppingCartOpen = () => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      ls.set(lsShoppingCartTrxInfo, this.state.trx_info);
      ls.set(lsShoppingCartCartList, this.state.cart_list);
      this.props.history.push("/shoppingcart");
    }
  };

  lostOnClose = () => {
    this.setState({ lost: false });
  };

  lostOpen = () => {
    this.setState({ lost: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ paddingBottom: 56 }}>
          <div className={classes.page}>
            <FullHeight canExceed>
              <div className={classes.paper}>
                {Parser().parse(this.state.content)}
              </div>
            </FullHeight>
          </div>
          <div className={classes.displayMobile}>
            <WhatsAppLink
              phone={this.state.otl_info.otl_whatsapp}
              label="Butuh Bantuan ?"
              text={
                this.state.otl_info.otl_name
                  ? `Hallo ${this.state.otl_info.otl_name}`
                  : "Hallo"
              }
            />
          </div>
        </div>

        <OlsNavbarDoor
          value="login"
          text={
            this.state.otl_info.otl_name
              ? `Hallo ${this.state.otl_info.otl_name}`
              : "Hallo"
          }
          outlet={this.state.otl_info}
          onCartClick={this.handleShoppingCartOpen}
        />

        <div className={classes.displayMobile}>
          <OlsBottomNav value=" " />
        </div>

        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Kebijakanprivasi);
