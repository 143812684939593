/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import "../../config";
import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FullHeight from "react-full-height";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import OlsNavbarDoor from "../../component/OlsNavbar/OlsNavbarDoor";
import WhatsAppLink from "../../component/WhatsAppLink/WhatsAppLink";

let isMounted = false;

const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    paddingTop: 62,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    padding: 15,
    margin: theme.spacing(3, 0, 2),
  },
  cardContent: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    padding: "4px 16px",
    "&:last-child": {
      paddingBottom: "4px",
    },
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

class OlsRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usr_info: ls.get("usr_info") || false,
      otl_info: ls.get(`otl_info`) || {},
      email: "",
      password: "",
      realname: "",
      phone: "",
      errorEmail: false,
      errorPassword: false,
      errorRealname: false,
      errorWhatsapp: false,
      errorMessage: "Harap isi data dengan benar dan lengkap",
      agreement: false,
      loading: false,
    };
  }

  componentDidMount() {
    isMounted = true;

    let otl_info = ls.get(`otl_info`) || {};
    this.setState({
      otl_info: otl_info,
    });
    if (_.isString(otl_info.otl_name)) {
      document.title = otl_info.otl_name;
    }

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });
    if (usr_info === false) {
      this.pageInfo();
    } else {
      this.props.history.push("/home"); // jika sudah login redirect ke home
    }
  }

  componentDidUpdate() {
    // console.log(this.state);
  }

  componentWillUnmount() {
    isMounted = false;
  }

  handleChange = (e) => {
    if (e.target.name === "email") {
      this.setState({ email: e.target.value });
    }
    if (e.target.name === "password") {
      this.setState({ password: e.target.value });
    }
    if (e.target.name === "realname") {
      this.setState({ realname: e.target.value });
    }
    if (e.target.name === "phone") {
      if (e.target.validity.valid) {
        this.setState({ phone: e.target.value });
      }
    }
  };

  handleCheckbox = (e) => {
    if (e.target.name === "agreement") {
      this.setState({ agreement: e.target.checked });
    }
  };

  handleKeyPress = (event) => {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  messageShow = (msg) => {
    this.setState({ errorMessage: msg });
  };

  handleSubmit = () => {
    if (isMounted) {
      const emailValid = this.state.email.match(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
      );
      if (emailValid) {
        this.setState({ errorEmail: false });
        if (this.state.password.length >= 6) {
          this.setState({ errorPassword: false });
          if (this.state.realname.length >= 3) {
            this.setState({ errorRealname: false });
            if (this.state.phone.length >= 9) {
              this.setState({ errorWhatsapp: false });
              if (this.state.agreement) {
                if (!this.state.loading) {
                  this.messageShow(
                    "Mohon tunggu proses pendaftaran sedang berlangsung"
                  );
                  this.setState({ loading: true });
                  let key = ls.get("usr_start") || " ";
                  let regEmail = this.state.email; //Aes.Ctr.encrypt(this.state.email, key, 256);
                  let regPassword = this.state.password; //Aes.Ctr.encrypt(this.state.password, key, 256);
                  const formdata = new FormData();
                  formdata.append("email", regEmail);
                  formdata.append("password", regPassword);
                  formdata.append("realname", this.state.realname);
                  formdata.append("phone", this.state.phone);
                  axios
                    .post(base_url + "/olshop_api/acc/acc_register/", formdata)
                    .then((response) => {
                      if (isMounted) {
                        this.setState({ loading: false });
                        if (_.isString(response.data.status)) {
                          if (response.data.status === "OK") {
                            if (_.isObject(response.data.usr_info)) {
                              this.setState({
                                usr_info: response.data.usr_info,
                              });
                              ls.set("usr_info", this.state.usr_info);
                              this.props.history.push("/home");
                            } else {
                              this.setState({ usr_info: false });
                              ls.set("usr_info", this.state.usr_info);
                              this.messageShow("Error tidak diketahui");
                            }
                          } else {
                            this.setState({ usr_info: false });
                            ls.set("usr_info", this.state.usr_info);
                            if (_.isString(response.data.message)) {
                              this.messageShow(response.data.message);
                            } else {
                              this.messageShow("Error tidak diketahui");
                            }
                          }
                        } else {
                          this.setState({ usr_info: false });
                          ls.set("usr_info", this.state.usr_info);
                          this.messageShow("Error tidak diketahui");
                        }
                      }
                    });
                }
              } else {
                this.messageShow(
                  "Silahkan setujui ketentuan dan kebijakan privasi"
                );
              }
            } else {
              this.messageShow("Nomor WhatsApp minimal 9 angka");
              this.setState({ errorWhatsapp: true });
            }
          } else {
            this.messageShow("Nama Lengkap minimal 3 karakter");
            this.setState({ errorRealname: true });
          }
        } else {
          this.messageShow("Password minimal 6 karakter");
          this.setState({ errorPassword: true });
        }
      } else {
        this.messageShow("Alamat email tidak valid");
        this.setState({ errorEmail: true });
      }
    }
  };

  pageInfo = () => {
    this.setState({ loading: true });
    const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
    const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios
      .post(base_url + "/olshop_api/page/info/", formdatau)
      .then((response) => {
        if (isMounted) {
          this.setState({ loading: false });
          if (_.isObject(response.data.otl_info)) {
            let otl_info = response.data.otl_info;
            this.setState({
              otl_info: otl_info,
            });
            ls.set(`otl_info`, otl_info);
            if (_.isString(otl_info.otl_name)) {
              document.title = otl_info.otl_name;
            }
          }
          if (_.isObject(response.data.trx_info)) {
            const trxInfoResp = response.data.trx_info;
            if (_.isString(trxInfoResp.trx_uxid)) {
              ls.set(lsShoppingCartTrxInfo, trxInfoResp);
            }
          }
          if (_.isArray(response.data.cart_list)) {
            const cartListResp = response.data.cart_list;
            ls.set(lsShoppingCartCartList, cartListResp);
          }
          if (_.isObject(response.data.usr_info)) {
            this.setState({ usr_info: response.data.usr_info });
            ls.set("usr_info", response.data.usr_info);
            this.props.history.push("/home");
          } else {
            this.setState({ usr_info: false });
            ls.set("usr_info", false);
          }
        }
      });
  };

  handleShoppingCartOpen = () => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      ls.set(lsShoppingCartTrxInfo, this.state.trx_info);
      ls.set(lsShoppingCartCartList, this.state.cart_list);
      this.props.history.push("/shoppingcart");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div style={{ paddingBottom: 56 }}>
          <div className={classes.page}>
            <FullHeight canExceed>
              <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Pendaftaran
                  </Typography>
                  <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="email"
                          label="Alamat Email"
                          name="email"
                          autoFocus
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                          error={this.state.errorEmail}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                          error={this.state.errorPassword}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="realname"
                          variant="outlined"
                          required
                          fullWidth
                          id="realname"
                          label="Nama Lengkap"
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                          error={this.state.errorRealname}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="phone"
                          variant="outlined"
                          required
                          fullWidth
                          id="phone"
                          label="Nomor WhatsApp"
                          type="number"
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                          error={this.state.errorWhatsapp}
                          autoComplete="off"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="agreement"
                              onChange={this.handleCheckbox}
                              color="primary"
                            />
                          }
                          label="Saya setuju ketentuan dan kebijakan privasi"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <CardContent className={classes.cardContent}>
                            <Typography variant="subtitle2">
                              {this.state.errorMessage}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={this.handleSubmit}
                    >
                      DAFTAR
                    </Button>
                    <Grid container justify="flex-end">
                      <Grid item style={{ paddingTop: 10 }}>
                        <Link to="/login" variant="body2">
                          Login klik disini
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                </div>
                <Box mt={10}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {"Baca "}
                    <Link to="/ketentuan" variant="body2">
                      Ketentuan
                    </Link>{" "}
                    dan{" "}
                    <Link to="/kebijakanprivasi" variant="body2">
                      Kebijakan Privasi
                    </Link>
                  </Typography>
                </Box>
                <Box mb={10}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {"Copyright © "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      color="inherit"
                      href="/"
                    >
                      {this.state.otl_info.otl_name
                        ? this.state.otl_info.otl_name
                        : "Sistem Kasir Android"}
                    </a>{" "}
                    {new Date().getFullYear()}
                    {"."}
                  </Typography>
                </Box>
              </Container>
            </FullHeight>
          </div>
          <div className={classes.displayMobile}>
            <WhatsAppLink
              phone={this.state.otl_info.otl_whatsapp}
              label="Butuh Bantuan ?"
              text={
                this.state.otl_info.otl_name
                  ? `Hallo ${this.state.otl_info.otl_name}`
                  : "Hallo"
              }
            />
          </div>
        </div>

        <OlsNavbarDoor
          value="register"
          text={
            this.state.otl_info.otl_name
              ? `Hallo ${this.state.otl_info.otl_name}`
              : "Hallo"
          }
          outlet={this.state.otl_info}
          onCartClick={this.handleShoppingCartOpen}
        />

        <div className={classes.displayMobile}>
          <OlsBottomNav value=" " />
        </div>

        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(OlsRegister);
