import React, { Fragment, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import WhatsAppIcon from "../WhatsAppIcon/WhatsAppIcon";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    padding: 0,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linkActive: {
    color: theme.palette.secondary.main,
  },
  linkNotActive: {
    color: theme.palette.primary.main,
  },
  appBarWrapper: {
    backgroundColor: theme.palette.color.background,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    height: 62,
    boxShadow: "0 3px 6px rgba(51,51,51,0.05)",
  },
  appBar: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
  },
  appBarM: {
    backgroundColor: theme.palette.primary.main,
    maxWidth: theme.palette.width.maxWidth,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
  },
  appBarTitleDesktop: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    flexGrow: 1,
  },
  appBarTitleMobile: {
    fontWeight: "bold",
    flexGrow: 1,
  },
  appBarButton: {
    paddingRight: 5,
    fontWeight: "bold",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  appToolbar: {
    height: 62,
    minHeight: 62,
    maxHeight: 62,
  },
  appBarRightIcon: {
    marginLeft: 20,
  },
  searchContent: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchInputRoot: {
    color: "inherit",
  },
  searchInputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const OlsNavbarProducts = (props) => {
  const classes = useStyles();
  const {
    outlet,
    value,
    title,
    text,
    cartCount,
    onCartClick,
    onSearchChange,
    onSearchSubmit,
  } = props;
  const [otl_info, setOtlInfo] = React.useState({});

  useEffect(() => {
    if (outlet) {
      setOtlInfo(outlet);
    }
  }, [outlet]);

  const handleShoppingCartOpen = () => {
    onCartClick();
  };

  const handleSearchOnChange = (event) => {
    onSearchChange(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
      onSearchSubmit(event.target.value);
    }
  };

  return (
    <Fragment>
      <div className={classes.displayDesktop}>
        <div className={classes.page}>
          <AppBar elevation={0} color="inherit" className={classes.appBar}>
            <Toolbar className={classes.appToolbar}>
              <Typography
                className={classes.appBarTitleDesktop}
                variant="h6"
                noWrap
              >
                {otl_info.otl_name}
              </Typography>

              <div className={classes.appBarButton}>
                <Button
                  component={Link}
                  to="/home"
                  className={
                    value === "home"
                      ? classes.linkActive
                      : classes.linkNotActive
                  }
                >
                  Home
                </Button>
                <Button
                  component={Link}
                  to="/products"
                  className={
                    value === "products"
                      ? classes.linkActive
                      : classes.linkNotActive
                  }
                >
                  Produk
                </Button>
                <Button
                  component={Link}
                  to="/trx"
                  className={
                    value === "trx" ? classes.linkActive : classes.linkNotActive
                  }
                >
                  Belanja
                </Button>
                <Button
                  component={Link}
                  to="/akun"
                  className={
                    value === "akun"
                      ? classes.linkActive
                      : classes.linkNotActive
                  }
                >
                  Akun
                </Button>
              </div>

              <div className={classes.displayDesktop}>
                <div className={classes.searchContent}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Cari produk"
                    onChange={handleSearchOnChange}
                    onKeyPress={handleSearchKeyPress}
                    classes={{
                      root: classes.searchInputRoot,
                      input: classes.searchInputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </div>

              <div className={classes.displayDesktop}>
                <WhatsAppIcon phone={otl_info.otl_whatsapp} text={text} />
              </div>

              <div className={classes.appBarRightIcon}>
                <IconButton color="primary" onClick={handleShoppingCartOpen}>
                  <Badge badgeContent={cartCount} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <div className={classes.appBarWrapper}></div>
      </div>
      <div className={classes.displayMobile}>
        <div className={classes.page}>
          <AppBar className={classes.appBarM}>
            <Toolbar className={classes.appToolbar}>
              <Typography
                className={classes.appBarTitleMobile}
                variant="h6"
                noWrap
              >
                {title}
              </Typography>
              <IconButton color="inherit" onClick={handleShoppingCartOpen}>
                <Badge badgeContent={cartCount} color="secondary">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    </Fragment>
  );
};

export default OlsNavbarProducts;
