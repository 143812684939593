/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import NumberFormat from "react-number-format";
import Truncate from "react-truncate";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 4,
  },
  detail: {
    flex: 1,
  },
  title: {
    color: theme.palette.primary.main,
  },
  infoWrapper: {
    height: "100%",
  },
  priceWrapper: {
    height: "100%",
  },
  status: {
    color: theme.palette.primary.main,
  },
}));

const OlsTrxRow = (props) => {
  const classes = useStyles();
  const { data, onOpen } = props;

  return (
    <div className={classes.root}>
      <Card onClick={() => onOpen(data)}>
        <CardActionArea>
          <CardContent>
            <Grid container spacing={2} justify="space-between">
              <Grid item md={8} xs={8} className={classes.detail}>
                <Grid
                  item
                  container
                  className={classes.infoWrapper}
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item style={{ width: "100%" }}>
                    <div>
                      <Typography className={classes.title} variant="h5">
                        <Truncate lines={1} ellipsis="...">
                          {data.trx_number}
                        </Truncate>
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Typography variant="body2">{data.trx_datetime}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={4} xs={4}>
                <Grid
                  item
                  container
                  className={classes.priceWrapper}
                  direction="column"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography
                      style={
                        data.trx_status === "DITOLAK"
                          ? {
                              color: "red",
                              fontWeight: "bold",
                              textDecoration: "line-through",
                            }
                          : {}
                      }
                      variant="h6"
                    >
                      <NumberFormat
                        value={data.trx_total}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.status} variant="body2">
                      {data.trx_terms}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

OlsTrxRow.defaultProps = {
  data: {
    trx_uxid: "",
    trx_parent_uxid: "",
    trx_order_uxid: "",
    trx_type: "",
    trx_name: "",
    trx_number: "",
    trx_worker_type: "",
    trx_worker_uxid: "",
    trx_worker_realname: "",
    trx_client_uxid: "",
    trx_client_realname: "",
    trx_client_phone: "",
    trx_client_company: "",
    trx_client_email: "",
    trx_client_addr1: "",
    trx_client_addr2: "",
    trx_client_addr3: "",
    trx_client_addr4: "",
    trx_client_addr5: "",
    trx_client_point: 0,
    trx_client_value: 0,
    trx_client_lat: "",
    trx_client_lng: "",
    trx_weight: 0,
    trx_value: 0,
    trx_tax_percent: 0,
    trx_tax_value: 0,
    trx_shipping_detail: "",
    trx_shipping_cost: 0,
    trx_other_cost: 0,
    trx_total: 0,
    trx_profit: 0,
    trx_bulat: 0,
    trx_amal: 0,
    trx_payment_type: "",
    trx_payment_pay: 0,
    trx_payment_change: 0,
    trx_payment_paid: 0,
    trx_payment_pending: 0,
    trx_payment_code: "",
    trx_payment_note: "",
    trx_payment_status: "",
    trx_tempo_deadline: 0,
    trx_tempo_expired: 0,
    trx_lat: "",
    trx_lng: "",
    trx_note: "",
    trx_terms: "",
    trx_letter1: "",
    trx_letter2: "",
    trx_ts: 0,
    trx_datetime: "",
    trx_hour: "",
    trx_day: "",
    trx_month: "",
    trx_year: "",
    trx_sync: "",
    trx_status: "",
  },
};

export default OlsTrxRow;
