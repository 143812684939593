/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 4,
  },
  image: {
    width: 80,
    height: 80,
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    minWidth: 80,
    minHeight: 80,
    borderRadius: 10,
  },
  detail: {
    flex: 1,
  },
  title: {
    color: theme.palette.primary.main,
  },
  infoWrapper: {
    height: "100%",
  },
  priceWrapper: {
    height: "100%",
  },
  discount: {
    position: "absolute",
    top: 10,
    backgroundColor: "red",
    padding: 5,
    color: "white",
    borderRadius: 20,
    minWidth: 50,
    textAlign: "center",
    boxShadow: "1px 3px 1px #9E9E9E",
    fontWeight: "bold",
  },
}));

const OlsProductRow = (props) => {
  const classes = useStyles();
  const { data, onSelect } = props;

  let discountStr = "";
  if (data.product_discount_value > 0) {
    if (data.product_discount_type === "PERCENTAGE") {
      discountStr = " %";
    }
  }

  return (
    <div className={classes.root}>
      <Card onClick={() => onSelect(data)}>
        <CardActionArea>
          <CardContent>
            <Grid container spacing={2} justify="space-between">
              <Grid item>
                <Grid item>
                  <img
                    alt=""
                    className={classes.image}
                    src={data.product_image_url}
                  />
                  {data.product_discount_value > 0 && (
                    <div className={classes.discount}>
                      <Typography variant="body2">
                        <NumberFormat
                          value={data.product_discount_value}
                          isNumericString
                          displayType="text"
                          decimalScale={0}
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                        {discountStr}
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item className={classes.detail}>
                <Grid
                  item
                  container
                  className={classes.infoWrapper}
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item style={{ width: "100%" }}>
                    <Typography className={classes.title} variant="body1">
                      {data.product_name}
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <Typography variant="caption">
                      {data.product_code}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  item
                  container
                  className={classes.priceWrapper}
                  direction="column"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography variant="body1">
                      <NumberFormat
                        value={data.product_sale_price}
                        isNumericString
                        displayType="text"
                        decimalScale={0}
                        thousandSeparator="."
                        decimalSeparator=","
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      {data.product_unit}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

OlsProductRow.defaultProps = {
  data: {
    product_uxid: "",
    product_name: "",
    product_code: "",
    product_detail: "",
    product_unit: "",
    product_sale_price: 0,
    product_discount_type: "",
    product_discount_value: 0,
    product_stk_qty: 0,
    product_image_url: "",
  },
};

export default OlsProductRow;
