/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Container } from "@material-ui/core";
import "typeface-roboto";
import NumberFormat from "react-number-format";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import PlusIcon from "@material-ui/icons/AddCircle";
import MinusIcon from "@material-ui/icons/RemoveCircle";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButtonMinus: {
    color: theme.palette.secondary.main,
    padding: 10,
  },
  iconButtonPlus: {
    color: theme.palette.primary.main,
    padding: 10,
  },
  inputWrapper: {
    marginTop: 20,
  },
  dialogContentStyle: {
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonDeleteStyle: {
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      style={{ textAlign: "center" }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const OlsCartEdit = (props) => {
  const classes = useStyles();
  const [fields, setFields] = React.useState({
    cart_uxid: "",
    cart_qty: 0,
    cart_note: "",
  });

  const { onClose, onSave, onDelete, data, open } = props;

  const editInit = () => {
    if (open) {
      let qty = data.cart_qty;
      const valStr = `${qty}`;
      const arrVal = valStr.split(".");
      if (arrVal.length > 1) {
        const decimalStr = arrVal[1];
        const decimalDbl = Number(decimalStr.replace(/[^0-9.-]+/g, ""));
        if (decimalDbl <= 0) {
          const valStrZ = arrVal[0];
          const valDblZ = Number(valStrZ.replace(/[^0-9.-]+/g, ""));
          qty = valDblZ;
        }
      }
      setFields({
        ...fields,
        cart_uxid: data.cart_uxid,
        cart_qty: qty,
        cart_note: data.cart_note,
      });
    }
  };

  useEffect(editInit, [open, data]);

  const handleClose = () => {
    onClose();
  };

  const handleDelete = () => {
    onDelete(fields);
  };

  const handleSave = () => {
    const valStr = `${fields.cart_qty} `;
    const valDbl = Number(valStr.replace(/[^0-9.-]+/g, ""));
    if (valDbl > 0) {
      onSave(fields);
    }
  };

  const handleAmountChange = (event) => {
    setFields({
      ...fields,
      cart_qty: event.target.value,
    });
  };
  const handleNoteChange = (event) => {
    setFields({
      ...fields,
      cart_note: event.target.value,
    });
  };

  const handleMinus = () => {
    const valStr = `${fields.cart_qty} `;
    const valDbl = Number(valStr.replace(/[^0-9.-]+/g, ""));
    if (valDbl > 1) {
      setFields({
        ...fields,
        cart_qty: valDbl - 1,
      });
    }
  };

  const handlePlus = () => {
    const valStr = `${fields.cart_qty} `;
    const valDbl = Number(valStr.replace(/[^0-9.-]+/g, ""));
    setFields({
      ...fields,
      cart_qty: valDbl + 1,
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.titleStyle} id="simple-dialog-title">
        {data.cart_product_name}
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <Container>
          <Typography className={classes.textCenter} variant="body1">
            Jumlah
          </Typography>
          <div className={classes.paper}>
            <IconButton
              className={classes.iconButtonMinus}
              onClick={handleMinus}
            >
              <MinusIcon />
            </IconButton>
            <TextField
              className={classes.input}
              autoComplete="off"
              value={fields.cart_qty}
              onChange={handleAmountChange}
              name="qty"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            <IconButton className={classes.iconButtonPlus} onClick={handlePlus}>
              <PlusIcon />
            </IconButton>
          </div>
          <div className={classes.inputWrapper}>
            <Typography className={classes.textCenter} variant="body1">
              Catatan
            </Typography>
            <TextField
              fullWidth
              autoComplete="off"
              value={fields.cart_note}
              onChange={handleNoteChange}
              name="note"
              inputProps={{ style: { textAlign: "center" } }}
            />
          </div>
          <Divider />
        </Container>
      </DialogContent>
      <DialogActions className={classes.buttonActionsStyle}>
        <Button
          className={classes.buttonStyle}
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          TUTUP
        </Button>
        <Button
          className={classes.buttonDeleteStyle}
          onClick={handleDelete}
          color="primary"
          variant="contained"
        >
          HAPUS
        </Button>
        <Button
          className={classes.buttonStyle}
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          SIMPAN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OlsCartEdit.defaultProps = {
  data: {
    cart_uxid: "",
    cart_trx_uxid: "",
    cart_parent_uxid: "",
    cart_type: "",
    cart_flow: "",
    cart_group: "",
    cart_worker_type: "",
    cart_worker_uxid: "",
    cart_worker_realname: "",
    cart_client_uxid: "",
    cart_client_realname: "",
    cart_product_uxid: "",
    cart_product_divisi: "",
    cart_product_departemen: "",
    cart_product_sdepartemen: "",
    cart_product_category: "",
    cart_product_type: "",
    cart_product_code: "",
    cart_product_name: "",
    cart_product_unit: "",
    cart_product_weight: 0,
    cart_product_base_price: 0,
    cart_product_sale_price: 0,
    cart_product_grosir_price: 0,
    cart_product_grosir_qty: 0,
    cart_product_tbm_price: 0,
    cart_product_tbm_value: 0,
    cart_product_tbm_qty: 0,
    cart_product_tbm_status: "",
    cart_product_final_price: 0,
    cart_product_discount_type: "",
    cart_product_discount_value: 0,
    cart_product_konsinyasi: "",
    cart_pack_item_qty: 0,
    cart_qty: 0,
    cart_weight: 0,
    cart_discount_type: "",
    cart_discount_value: 0,
    cart_discount_price_unit: 0,
    cart_discount_price_total: 0,
    cart_base_total: 0,
    cart_sale_total: 0,
    cart_profit: 0,
    cart_lat: "",
    cart_lng: "",
    cart_note: "",
    cart_detail: "",
    cart_ts: 0,
    cart_hour: "",
    cart_day: "",
    cart_month: "",
    cart_year: "",
    cart_step: "",
    cart_payment_status: "",
    cart_status: "",
  },
};
export default OlsCartEdit;
