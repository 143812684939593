/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { Divider } from "@material-ui/core";
import "typeface-roboto";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    marginBottom: 5,
  },
  dialogContentStyle: {
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonSecondary: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const EditDialog = (props) => {
  const classes = useStyles();
  const { data, onSave, onClose, open } = props;

  const [realname, setRealname] = React.useState("");
  const [addr1, setAddr1] = React.useState("");
  const [addr2, setAddr2] = React.useState("");
  const [addr3, setAddr3] = React.useState("");
  const [addr4, setAddr4] = React.useState("");
  const [addr5, setAddr5] = React.useState("");
  const [phone, setPhone] = React.useState("");

  useEffect(() => {
    setRealname(data.trx_client_realname);
    setAddr1(data.trx_client_addr1);
    setAddr2(data.trx_client_addr2);
    setAddr3(data.trx_client_addr3);
    setAddr4(data.trx_client_addr4);
    setAddr5(data.trx_client_addr5);
    setPhone(data.trx_client_phone);
  }, [data]);

  useEffect(() => {
    if (open) {
      setRealname(data.trx_client_realname);
      setAddr1(data.trx_client_addr1);
      setAddr2(data.trx_client_addr2);
      setAddr3(data.trx_client_addr3);
      setAddr4(data.trx_client_addr4);
      setAddr5(data.trx_client_addr5);
      setPhone(data.trx_client_phone);
    }
  }, [open, data]);

  const handleSave = () => {
    data.trx_client_realname = realname;
    data.trx_client_addr1 = addr1;
    data.trx_client_addr2 = addr2;
    data.trx_client_addr3 = addr3;
    data.trx_client_addr4 = addr4;
    data.trx_client_addr5 = addr5;
    data.trx_client_phone = phone;
    onSave(data);
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (e) => {
    if (e.target.name === "realname") {
      setRealname(e.target.value);
    }
    if (e.target.name === "addr1") {
      setAddr1(e.target.value);
    }
    if (e.target.name === "addr2") {
      setAddr2(e.target.value);
    }
    if (e.target.name === "addr3") {
      setAddr3(e.target.value);
    }
    if (e.target.name === "addr4") {
      setAddr4(e.target.value);
    }
    if (e.target.name === "addr5") {
      setAddr5(e.target.value);
    }
    if (e.target.name === "phone") {
      setPhone(e.target.value);
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      fullWidth
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.titleStyle} id="simple-dialog-title">
        Alamat Lengkap
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <TextField
          fullWidth
          autoComplete="off"
          name="realname"
          placeholder="Nama Lengkap"
          defaultValue={realname}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          autoComplete="off"
          name="addr1"
          placeholder="Alamat"
          defaultValue={addr1}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          autoComplete="off"
          name="addr2"
          defaultValue={addr2}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          autoComplete="off"
          name="addr3"
          defaultValue={addr3}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          autoComplete="off"
          name="addr4"
          defaultValue={addr4}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          autoComplete="off"
          name="addr5"
          defaultValue={addr5}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          autoComplete="off"
          name="phone"
          placeholder="Nomor WhatsApp"
          defaultValue={phone}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions className={classes.buttonActionsStyle}>
        <Button
          className={classes.buttonSecondary}
          onClick={handleClose}
          variant="contained"
        >
          TUTUP
        </Button>
        <Button
          className={classes.buttonPrimary}
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          SIMPAN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TrxAlamatLengkap = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { editable, data, onChange } = props;

  const handleClickOpen = () => {
    if (_.isBoolean(editable)) {
      if (editable) {
        setOpen(true);
      }
    }
  };

  const handleSave = (dt) => {
    onChange(dt);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card className={classes.cardWrapper}>
      <CardHeader
        title="Alamat Lengkap"
        subheader="Alamat lengkap digunakan untuk lokasi pengiriman dan nomor telepon digunakan untuk proses konfirmasi dari belanja anda"
      />
      <CardActionArea onClick={handleClickOpen}>
        <CardContent style={{ minHeight: 100 }}>
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_realname}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr1}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr2}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr3}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr4}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_addr5}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ minHeight: 23 }}>
            {data.trx_client_phone}
          </Typography>
          <Divider />
        </CardContent>
      </CardActionArea>
      <EditDialog
        data={data}
        onSave={handleSave}
        onClose={handleClose}
        open={open}
      />
    </Card>
  );
};

export default TrxAlamatLengkap;
