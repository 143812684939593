/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/state-in-constructor */
import "../../config";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import VisibilitySensor from "react-visibility-sensor";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import SelectAllOutlinedIcon from "@material-ui/icons/SelectAllOutlined";
import OlsFooter from "../../component/OlsFooter/OlsFooter";
import OlsNavbarTrx from "../../component/OlsNavbar/OlsNavbarTrx";
import OlsTrxRow from "../../component/OlsTrxRow/OlsTrxRow";
import WhatsAppLink from "../../component/WhatsAppLink/WhatsAppLink";
import OlsBottomNav from "../../container/OlsBottomNav/OlsBottomNav";
import OlsSearch from "../../container/OlsSearch/OlsSearch";

let isMounted = false;

const useStyles = (theme) => ({
  displayMobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  displayDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  page: {
    backgroundColor: theme.palette.color.background,
    maxWidth: theme.palette.width.appWidth,
    paddingTop: 62,
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingTop: {
    height: 5,
    width: "100%",
    position: "fixed",
    top: 0,
    left: "auto",
    right: "auto",
    paddingRight: "0px !important",
    zIndex: 1101,
  },
  content: {
    padding: 4,
    display: "block",
    minHeight: 1000,
    "&::after": {
      content: '""',
      clear: "both",
      display: "table",
    },
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

class Trx extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usr_info: ls.get("usr_info") || false,
      otl_info: ls.get(`otl_info`) || {},
      trx_list: ls.get(`otl_trx_list`) || [],
      trx_info: ls.get(`otl_ShoppingCart_TrxInfo`) || {},
      cart_list: [],
      loading: false,
      more: false,
      search_text: "",
    };
  }

  componentDidMount() {
    isMounted = true;

    let otl_info = ls.get(`otl_info`) || {};
    this.setState({
      otl_info: otl_info,
    });
    if (_.isString(otl_info.otl_name)) {
      document.title = otl_info.otl_name;
    }

    let usr_info = ls.get("usr_info") || false;
    this.setState({
      usr_info: usr_info,
    });
    if (usr_info !== false) {
      if (_.isString(usr_info.usr_uxid)) {
        const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
        const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
        const trxInfo = ls.get(lsShoppingCartTrxInfo);
        if (_.isObject(trxInfo)) {
          if (_.isString(trxInfo.trx_client_uxid)) {
            if (trxInfo.trx_client_uxid === usr_info.usr_uxid) {
              this.setState({ trx_info: trxInfo });
              const cartList = ls.get(lsShoppingCartCartList);
              if (_.isArray(cartList)) {
                this.setState({ cart_list: cartList });
              }
            }
          }
        }
      }
    }

    this.pageInfo();
  }

  componentWillUnmount() {
    isMounted = false;
  }

  pageInfo = () => {
    this.setState({ loading: true });
    const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
    const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
    const formdatau = new FormData();
    formdatau.append("info", "uxid");
    axios
      .post(base_url + "/olshop_api/page/info/", formdatau)
      .then((response) => {
        if (isMounted) {
          this.setState({ loading: false });
          if (_.isObject(response.data.usr_info)) {
            this.setState({ usr_info: response.data.usr_info });
            ls.set("usr_info", response.data.usr_info);
          } else {
            this.setState({ usr_info: false });
            ls.set("usr_info", false);
            this.props.history.push("/login");
          }
          if (_.isObject(response.data.otl_info)) {
            let otl_info = response.data.otl_info;
            this.setState({
              otl_info: otl_info,
            });
            ls.set(`otl_info`, otl_info);
            if (_.isString(otl_info.otl_name)) {
              document.title = otl_info.otl_name;
            }
          }
          if (_.isArray(response.data.cart_list)) {
            const cartListResp = response.data.cart_list;
            this.setState({ cart_list: cartListResp });
            ls.set(lsShoppingCartCartList, cartListResp);
          }
          if (_.isObject(response.data.trx_info)) {
            const trxInfoResp = response.data.trx_info;
            if (_.isString(trxInfoResp.trx_uxid)) {
              if (trxInfoResp.trx_status !== "TEMP") {
                ls.set(lsShoppingCartTrxInfo, {});
                ls.set(lsShoppingCartCartList, []);
                this.setState({ trx_info: {} });
                this.setState({ cart_list: [] });
              } else {
                this.setState({ trx_info: trxInfoResp });
                ls.set(lsShoppingCartTrxInfo, trxInfoResp);
              }
            }
          }
          this.doListLoad();
        }
      });
  };

  trxViewOpen = (data) => {
    if (isMounted) {
      this.props.history.push(`/trxview/${data.trx_uxid}`);
    }
  };

  handleSearchOnChange = (value) => {
    if (isMounted) {
      this.setState({
        search_text: value,
      });
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("search", value);
      formdata.append("last_count", 0);
      axios
        .post(base_url + "/olshop_api/trx/trx_search/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isArray(response.data.trx_list)) {
              if (_.size(response.data.trx_list) > 0) {
                this.setState({
                  more: true,
                });
              } else {
                this.setState({
                  more: false,
                });
              }
              this.setState({
                trx_list: response.data.trx_list,
              });
            }
          }
        });
    }
  };

  handleSearchOnSubmit = (value) => {
    if (isMounted) {
      this.setState({
        search_text: value,
      });
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("search", value);
      formdata.append("last_count", 0);
      axios
        .post(base_url + "/olshop_api/trx/trx_search/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isArray(response.data.trx_list)) {
              if (_.size(response.data.trx_list) > 0) {
                this.setState({
                  more: true,
                });
              } else {
                this.setState({
                  more: false,
                });
              }
              this.setState({
                trx_list: response.data.trx_list,
              });
            }
          }
        });
    }
  };

  handleShoppingCartOpen = () => {
    if (isMounted) {
      const lsShoppingCartTrxInfo = `otl_ShoppingCart_TrxInfo`;
      const lsShoppingCartCartList = `otl_ShoppingCart_CartList`;
      ls.set(lsShoppingCartTrxInfo, this.state.trx_info);
      ls.set(lsShoppingCartCartList, this.state.cart_list);
      this.props.history.push("/shoppingcart");
    }
  };

  doListLoad = () => {
    if (isMounted) {
      this.setState({ loading: true });
      const formdata = new FormData();
      formdata.append("last_count", 0);
      axios
        .post(base_url + "/olshop_api/trx/trx_list/", formdata)
        .then((response) => {
          if (isMounted) {
            this.setState({ loading: false });
            if (_.isArray(response.data.trx_list)) {
              if (_.size(response.data.trx_list) > 0) {
                this.setState({
                  more: true,
                });
              } else {
                this.setState({
                  more: false,
                });
              }
              this.setState({
                trx_list: response.data.trx_list,
              });
              ls.set(`otl_trx_list`, response.data.trx_list);
            }
          }
        });
      window.scrollTo(0, 0);
    }
  };

  doListMore = () => {
    if (isMounted) {
      const lastCount = _.size(this.state.trx_list);
      const formdata = new FormData();
      formdata.append("last_count", lastCount);
      axios
        .post(base_url + "/olshop_api/trx/trx_list/", formdata)
        .then((response) => {
          if (isMounted) {
            if (_.isArray(response.data.trx_list)) {
              if (_.size(response.data.trx_list) > 0) {
                this.setState({
                  more: true,
                });
                const trxList = _.unionBy(
                  this.state.trx_list,
                  response.data.trx_list,
                  "trx_uxid"
                );
                this.setState({
                  trx_list: trxList,
                });
              } else {
                this.setState({
                  more: false,
                });
              }
            }
          }
        });
    }
  };

  doSearchMore = () => {
    if (isMounted) {
      const lastCount = _.size(this.state.trx_list);
      const formdata = new FormData();
      formdata.append("search", this.state.search_text);
      formdata.append("last_count", lastCount);
      axios
        .post(base_url + "/olshop_api/trx/trx_search/", formdata)
        .then((response) => {
          if (isMounted) {
            if (_.isArray(response.data.trx_list)) {
              if (_.size(response.data.trx_list) > 0) {
                this.setState({
                  more: true,
                });
                const trxList = _.unionBy(
                  this.state.trx_list,
                  response.data.trx_list,
                  "trx_uxid"
                );
                this.setState({
                  trx_list: trxList,
                });
              } else {
                this.setState({
                  more: false,
                });
              }
            }
          }
        });
    }
  };

  handleLoadMore = (isMore) => {
    if (isMore) {
      this.setState({
        more: false,
      });
      if (_.isEmpty(this.state.search_text)) {
        this.doListMore();
      } else {
        this.doSearchMore();
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingBottom: 56, minHeight: 1000 }}>
        <div style={this.state.usr_info ? {} : { display: "none" }}>
          <div className={classes.page}>
            <div className={classes.displayMobile}>
              <OlsSearch
                onChange={this.handleSearchOnChange}
                onSubmit={this.handleSearchOnSubmit}
              />
            </div>
            <div className={classes.displayDesktop}>
              <div style={{ height: 5 }}></div>
              <div style={{ paddingTop: 35, paddingBottom: 5 }}>
                <Grid container justify="space-between">
                  <Grid item>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <div style={{ paddingTop: 5 }}>
                          <Grid container item>
                            <Grid item>
                              <SelectAllOutlinedIcon style={{ fontSize: 36 }} />
                            </Grid>
                            <Grid item>
                              <Typography
                                style={{ fontSize: 14, marginLeft: 5 }}
                                variant="overline"
                                gutterBottom
                                noWrap
                              >
                                Daftar Transaksi
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item container direction="column" justify="center">
                      <Grid item></Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-end"
                    >
                      <Grid item></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div style={{ paddingBottom: 56 }}>
              <div className={classes.content}>
                {this.state.trx_list.map((trx, index) => (
                  <OlsTrxRow
                    key={index}
                    data={trx}
                    onOpen={(data) => this.trxViewOpen(data)}
                  />
                ))}
              </div>
            </div>
          </div>

          <VisibilitySensor onChange={this.handleLoadMore}>
            <div>
              <div style={{ height: 5 }}>
                <LinearProgress
                  style={this.state.more ? {} : { display: "none" }}
                />
              </div>
            </div>
          </VisibilitySensor>

          <Divider />
          <OlsFooter outlet={this.state.otl_info} />

          <div className={classes.displayMobile}>
            <WhatsAppLink
              phone={this.state.otl_info.otl_whatsapp}
              label="Butuh Bantuan ?"
              text={
                this.state.otl_info.otl_name
                  ? `Hallo ${this.state.otl_info.otl_name}`
                  : "Hallo"
              }
            />
          </div>
        </div>

        <OlsNavbarTrx
          value="trx"
          title="Belanja"
          text={
            this.state.otl_info.otl_name
              ? `Hallo ${this.state.otl_info.otl_name}`
              : "Hallo"
          }
          outlet={this.state.otl_info}
          cartCount={_.size(this.state.cart_list)}
          onCartClick={this.handleShoppingCartOpen}
          onSearchChange={this.handleSearchOnChange}
          onSearchSubmit={this.handleSearchOnSubmit}
        />

        <div className={classes.displayMobile}>
          <OlsBottomNav value="trx" />
        </div>

        <div className={classes.loadingTop}>
          <LinearProgress
            style={this.state.loading ? {} : { display: "none" }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Trx);
