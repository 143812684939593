/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import QRCode from "react-qr-code";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1px 0px 5px 0px",
  },
}));

const UsrQRCode = (props) => {
  const classes = useStyles();
  const { data } = props;
  return (
    <div className={classes.root}>
      <Card>
        <CardHeader
          title="QR Code Member"
          subheader="Tunjukan QR Code untuk memudahkan pencarian member"
        />
        <CardContent style={{ paddingBottom: 30 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <QRCode value={data.usr_uxid ? data.usr_uxid : "1234567890"} />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UsrQRCode;
