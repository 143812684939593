/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { CardActionArea } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1px 0px 5px 0px",
  },
  content: {
    padding: "4px 16px",
    "&:last-child": {
      paddingBottom: "4px",
    },
  },
  label: {
    color: theme.palette.primary.main,
  },
  fullHeight: {
    height: "100%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  dialogContentStyle: {
    height: 120,
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const ChangeWhatsApp = (props) => {
  const classes = useStyles();
  const { onClose, value, open, onSave } = props;

  const [whatsapp, setWhatsApp] = React.useState(value);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [error, setError] = React.useState(false);

  const messageShow = (msg) => {
    setErrorMessage(msg);
  };

  const messageHide = () => {
    setErrorMessage("");
    setError(false);
  };

  const handleSubmit = () => {
    if (whatsapp.length >= 9) {
      onSave(whatsapp);
    } else {
      messageShow("Nomor WhatsApp minimal 9 angka");
      setError(true);
      setTimeout(() => {
        messageHide();
      }, 3000);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = (e) => {
    if (e.target.name === "whatsapp") {
      if (e.target.validity.valid) {
        setWhatsApp(e.target.value);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  const openInit = () => {
    if (open) {
      setWhatsApp(value);
    }
  };

  useEffect(openInit, [open]);

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      fullWidth
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.titleStyle} id="simple-dialog-title">
        Edit WhatsApp
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <TextField
          autoComplete="off"
          variant="outlined"
          margin="normal"
          fullWidth
          id="whatsapp"
          label="Masukan Nomor WhatsApp"
          name="whatsapp"
          autoFocus
          type="number"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          error={error}
          helperText={errorMessage}
          value={whatsapp}
          step="any"
        />
      </DialogContent>
      <DialogActions className={classes.buttonActionsStyle}>
        <Button
          className={classes.buttonStyle}
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          TUTUP
        </Button>
        <Button
          className={classes.buttonStyle}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          autoFocus
        >
          SIMPAN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const UsrWhatsApp = (props) => {
  const classes = useStyles();
  const { data, onChange } = props;
  const [edit, setEdit] = React.useState(false);

  const handleSave = (whatsapp) => {
    setEdit(false);
    data.usr_phone = whatsapp;
    onChange(data);
  };

  const handleClose = () => {
    setEdit(false);
  };

  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <div className={classes.root}>
      <Card>
        <CardActionArea onClick={handleEdit}>
          <CardContent className={classes.content}>
            <Grid container spacing={2}>
              <Grid item>
                <Grid
                  item
                  className={classes.fullHeight}
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Avatar className={classes.avatar}>
                      <WhatsAppIcon />
                    </Avatar>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  item
                  className={classes.fullHeight}
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Grid item>
                    <Typography variant="body1">{data.usr_phone}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <ChangeWhatsApp
          value={data.usr_phone}
          open={edit}
          onSave={handleSave}
          onClose={handleClose}
        />
      </Card>
    </div>
  );
};

export default UsrWhatsApp;
