/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    marginTop: '6px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '1rem',
  },
  iconButton: {
    padding: 10,
  },
}));

export default function OlsSearch(props) {
  const classes = useStyles();
  const { onChange, onSubmit } = props;
  const [search, setSearch] = React.useState('');

  const handleOnChange = (event) => {
    setSearch(event.target.value);
    onChange(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      event.preventDefault();
      setSearch(event.target.value);
      onSubmit(event.target.value);
    }
  };

  const handleClear = () => {
    setSearch('');
  };

  return (
    <Paper component="form" className={classes.root}>
      <IconButton className={classes.iconButton} onClick={handleClear}>
        <SearchIcon />
      </IconButton>
      <InputBase
        className={classes.input}
        placeholder="Cari..."
        value={search}
        onChange={handleOnChange}
        onKeyPress={handleKeyPress}
        inputProps={{ style: { returnKeyType: 'search' } }}
      />
    </Paper>
  );
}
