/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import "../../config";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import "typeface-roboto";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { CardActionArea } from "@material-ui/core";
import axios from "axios";
import _ from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";

let isMounted = false;

const useStyles = (theme) => ({
  root: {
    padding: "1px 0px 5px 0px",
  },
  content: {
    padding: "4px 16px",
    "&:last-child": {
      paddingBottom: "4px",
    },
  },
  fullHeight: {
    height: "100%",
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
  },
  dialogContentStyle: {
    height: 220,
    scrollbarWidth: 0,
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
  },
  titleStyle: {
    textAlign: "center",
  },
  buttonActionsStyle: {
    justifyContent: "space-between",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
});

let base_url = global.config.host;
if (base_url.length < 1) {
  base_url = window.location.origin;
}

const ChangePassword = (props) => {
  const { classes, onClose, open } = props;

  const [passwordOld, setPasswordOld] = React.useState("");
  const [errorOld, setErrorOld] = React.useState(false);
  const [errorMessageOld, setMessageOld] = React.useState("");
  const [passwordNew, setPasswordNew] = React.useState("");
  const [errorNew, setErrorNew] = React.useState(false);
  const [errorMessageNew, setMessageNew] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setErrorOld(false);
    setErrorNew(false);
    setMessageOld("");
    setMessageNew("");
    onClose();
  };

  const handleSubmit = () => {
    if (isMounted) {
      setErrorOld(false);
      setErrorNew(false);
      setMessageOld("");
      setMessageNew("");
      if (passwordNew.length >= 6) {
        setLoading(true);
        const formdata = new FormData();
        formdata.append("password_new", passwordNew);
        formdata.append("password_old", passwordOld);
        axios
          .post(base_url + "/olshop_api/acc/acc_password_update/", formdata)
          .then((response) => {
            if (isMounted) {
              setLoading(false);
              if (_.isString(response.data.status)) {
                if (response.data.status === "OK") {
                  if (_.isString(response.data.message)) {
                    setMessageNew(response.data.message);
                  }
                  setTimeout(() => {
                    handleClose();
                  }, 2000);
                } else if (_.isString(response.data.field)) {
                  if (response.data.field === "password_old") {
                    setErrorOld(true);
                    if (_.isString(response.data.message)) {
                      setMessageOld(response.data.message);
                    }
                  }
                  if (response.data.field === "password_new") {
                    setErrorNew(true);
                    if (_.isString(response.data.message)) {
                      setMessageNew(response.data.message);
                    }
                  }
                  if (response.data.field === "all_field") {
                    setErrorOld(true);
                    setErrorNew(true);
                    if (_.isString(response.data.message)) {
                      setMessageNew(response.data.message);
                    }
                  }
                } else {
                  setErrorOld(true);
                  setErrorNew(true);
                  if (_.isString(response.data.message)) {
                    setMessageNew(response.data.message);
                  } else {
                    setMessageNew("Error tidak diketahui");
                  }
                }
              } else {
                setErrorOld(true);
                setErrorNew(true);
                setMessageNew("Error tidak diketahui");
              }
            }
          });
      } else {
        setErrorNew(true);
        setMessageNew("Password minimal 6 karakter");
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "passwordOld") {
      setPasswordOld(e.target.value);
    }
    if (e.target.name === "passwordNew") {
      setPasswordNew(e.target.value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.target.type !== "textarea" && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  return (
    <Dialog
      maxWidth="xs"
      onClose={handleClose}
      fullWidth
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle className={classes.titleStyle} id="simple-dialog-title">
        Ubah Password
      </DialogTitle>
      <DialogContent className={classes.dialogContentStyle}>
        <TextField
          autoComplete="off"
          variant="outlined"
          margin="normal"
          fullWidth
          id="passwordOld"
          label="Masukan Password Lama"
          name="passwordOld"
          autoFocus
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          error={errorOld}
          helperText={errorMessageOld}
        />
        <TextField
          autoComplete="off"
          variant="outlined"
          margin="normal"
          fullWidth
          id="passwordNew"
          label="Masukan Password Baru"
          name="passwordNew"
          autoFocus
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          error={errorNew}
          helperText={errorMessageNew}
        />
        <div style={{ height: 5 }}>
          <LinearProgress style={loading ? {} : { display: "none" }} />
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonActionsStyle}>
        <Button
          className={classes.buttonStyle}
          onClick={handleClose}
          color="primary"
          variant="contained"
        >
          TUTUP
        </Button>
        <Button
          className={classes.buttonStyle}
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          autoFocus
        >
          SIMPAN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

class UsrChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  componentDidMount() {
    isMounted = true;
  }

  componentWillUnmount() {
    isMounted = false;
  }

  handleEditOpen = () => {
    this.setState({ edit: true });
  };

  handleEditClose = () => {
    this.setState({ edit: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Card>
          <CardActionArea onClick={this.handleEditOpen}>
            <CardContent className={classes.content}>
              <Grid container spacing={2}>
                <Grid item>
                  <Grid
                    item
                    className={classes.fullHeight}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                      </Avatar>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    item
                    className={classes.fullHeight}
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography variant="body1">Ubah Password</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
          <ChangePassword
            classes={classes}
            open={this.state.edit}
            onClose={this.handleEditClose}
          />
        </Card>
      </div>
    );
  }
}

export default withStyles(useStyles)(UsrChangePassword);
