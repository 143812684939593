module.exports = global.config = {
    host:'', 
    color: {
        primary: '#37474f',
        main: '#455a64',
        light: '#607d8b',
    },
    copyright: {
        name: 'SistemKasirAndroid',
        website: 'https://sistemkasirandroid.com'
    },
};